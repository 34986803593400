import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { combineLatest, of, Subject, Observable, NEVER, Subscription } from 'rxjs';
import { debounceTime, delay, filter, switchMap, take, takeUntil, startWith, withLatestFrom } from 'rxjs/operators';

import {
    BasicBetType,
    BetAmountType,
    Bet,
    enumBetModifier,
    enumRaceStatus,
    EventsService,
    FeatureToggleDataService,
    ISelectedBetAmount,
    ISelectedEntry,
    ITrack,
    ITrackBasic,
    MultiRaceExoticBetType,
    TracksDataService,
    TrackService,
    transformBetToWagerState,
    TranslateService,
    WagerState,
    JwtSessionService,
    PlayerGroupsService,
    WagerService,
} from '@cdux/ng-common';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';
import { ToastService } from '@cdux/ng-fragments';

import { DEFAULT_REDIRECT_PATH, LOGIN_REDIRECT_PATH } from 'app/app.routing.constants';
import { WageringViewEnum } from './enums/wagering-view.enum';
import { ViewSectionEnum } from './enums/view-section.enum';
import { ViewNavigationManager, WageringNavigationState } from './classes/view-navigation-manager.class';
import { RestrictedViewError } from './classes/view-selection-manager.class';
import { WagerManager } from './classes/wager-manager.class';
import { QuickBetLinkUtil } from 'app/shared/common/utils/QuickBetLinkUtil';
import { IAlternateSelections, ISelectionUpdate } from 'app/shared/program/interfaces/selection-update.interface';
import { CduxTitleService } from 'app/shared/common/services/title/title.service';
import { RaceDetailsRequestHandler } from 'app/shared/betpad/classes/race-details-request-handler.class';
import { BetSlipBusinessService } from 'app/shared/bet-slip/services/bet-slip.business.service';
import { ErrorBarError } from 'app/shared/bet-slip/interfaces/error-bar-error.interface';
import { FundingService } from '../../shared/funding/shared/services/funding.service';
import { WagerEvent } from '../../shared/wager-views/interfaces';
import { IBetError } from '../../shared/bet-slip/interfaces/bet-error.interface';
import { BET_ERROR_CODES } from '../../shared/bet-slip/enums/bet-error-codes.enum';
import { WagerEventTypeEnum } from '../../shared/wager-views/enums';
import { BetSuccessPanelComponent } from 'app/shared/bets/components/bet-success/bet-success-panel.component';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';
import { ViewStateService } from './services/view-state.service';
import { ViewStateGroupEnum } from './interfaces/view-state-store';
import { BetsViewEnum, BetsContainerComponent } from 'app/shared/bets/components/bets-container/bets-container.component';
import { MenuItemsEnum } from 'app/shared/menu-items/enums/menu-items.enum';
import { TodaysBetsContainerComponent } from 'app/shared/bets/components/todays-bets-container/todays-bets-container.component';
import { FundingDepositOptionsComponent } from 'app/shared/funding/components/deposit-options';
import { FullPageFundingConstants } from 'app/shared/funding/full-page-funding/full-page-funding.constants';

@Component({
    selector: 'cdux-view-foundation',
    templateUrl: './view-foundation.component.html',
    styleUrls: ['./view-foundation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewFoundationComponent implements OnInit, OnDestroy {
    private readonly SUCCESS_PANEL_DISPLAY_LENGTH = 3500;

    public WageringViewEnum = WageringViewEnum;

    /** INTERNAL CONTROLS **/
    private _navigationManager: ViewNavigationManager;
    private _wagerManager: WagerManager;
    private _kill: Subject<any> = new Subject<any>();
    private _raceDetailsHandler: RaceDetailsRequestHandler;

    /** END INTERNAL CONTROLS **/

    public selectedView: WageringViewEnum;
    private defaultView: WageringViewEnum = WageringViewEnum.CLASSIC;
    public selectedSection: ViewSectionEnum;
    public error: ErrorBarError;
    public eventStream: Subject<WagerEvent> = new Subject<WagerEvent>();

    // The selectedTrack and selectedRace exist to support components which
    // do not yet read directly from the wager state.
    public selectedTrack: ITrackBasic;
    public selectedRace: number;
    public selectedRaceStatus: enumRaceStatus;

    /* Getters/Setters */
    private _wagerState: WagerState;
    public set wagerState(wagerState: WagerState) {
        this._wagerState = wagerState;
        this.selectedTrack = !!wagerState ? wagerState.basicTrack : null;
        this.selectedRace = !!wagerState && !!wagerState.basicTrack ? wagerState.basicTrack.RaceNum : null;
        this._changeDetector.detectChanges();
    }
    public get wagerState() {
        return this._wagerState;
    }

    private _stashedEntrySelections: ISelectedEntry[][];
    private _splitBetButtonFT: boolean = false;
    private _tabletMediaQuery: Subscription;
    public isTabletPortrait = false;
    public ftFullPageDeposit = false;

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _betSlipService: BetSlipBusinessService,
        private _fundingService: FundingService,
        private _jwtSessionService: JwtSessionService,
        private _playerGroupsService: PlayerGroupsService,
        private _mediaQueryService: CduxMediaToggleService,
        private _tracksDataService: TracksDataService,
        private _translateService: TranslateService,
        private _changeDetector: ChangeDetectorRef,
        private _sidebarService: SidebarService,
        private _viewStateService: ViewStateService,
        private _title: CduxTitleService,
        private _toast: ToastService,
        private _featureToggleService: FeatureToggleDataService,
        private _eventsService: EventsService,
        private _wagerService: WagerService,
        localFeatureToggleService: FeatureToggleDataService,
    ) {
        this._navigationManager = new ViewNavigationManager(
            this._featureToggleService,
            this._mediaQueryService,
            this._playerGroupsService,
            this._jwtSessionService,
            this._viewStateService,
            this._eventsService
        );
        this._wagerManager = new WagerManager();
        this._raceDetailsHandler = new RaceDetailsRequestHandler(this._tracksDataService);
        this.ftFullPageDeposit = localFeatureToggleService.isFeatureToggleOn(FullPageFundingConstants.FULL_PAGE_DEPOSIT_FT);
    }

    ngOnInit() {
        this.eventStream
            .pipe(takeUntil(this._kill))
            .subscribe((e) => {
                switch (e.type) {
                    case WagerEventTypeEnum.ERROR_DISMISSED:
                    case WagerEventTypeEnum.WAGER_VALID:
                    case WagerEventTypeEnum.WAGER_NO_VALUE:
                        this.error = undefined;
                        break;
                    case WagerEventTypeEnum.WAGER_INVALID:
                    case WagerEventTypeEnum.WAGER_SAVE_ERROR:
                    case WagerEventTypeEnum.WAGER_SUBMISSION_ERROR:
                    case WagerEventTypeEnum.REPEAT_WAGER_SUBMISSION_ERROR:
                        this._handleWagerSubmissionError(e.message, e.data);
                        break;
                    case WagerEventTypeEnum.WAGER_SUBMISSION_SUCCESS:
                        this.onWagerSubmitted(e.message);
                        break;
                    case WagerEventTypeEnum.WAGER_SAVE_SUCCESS:
                        this.onWagerSaved();
                        break;
                }
                this._changeDetector.detectChanges();
            });

        this._navigationManager.listen()
            .pipe(debounceTime(100)) // prevent NavigationCancel event from rapid navigation
            .subscribe(
                (state: WageringNavigationState) => {
                    if (state.route) {
                        this._router.navigate(state.route, {
                            relativeTo: this._route.parent,
                            // use replaceUrl if adding route segments (filling in defaults)
                            replaceUrl: this._route.snapshot.url.length < state.route.length
                        });
                    }
                    this._wagerManager.updateNavigationState(state);
                    this.selectedSection = state.section;
                    this.selectedView = state.view;
                    this._changeDetector.detectChanges();
                },
                (error) => this._handleRestrictedNavigation(error)
            );

        this._wagerManager.listen()
            .subscribe(
                (state: WagerState) => {
                    this.wagerState = state;
                    this._changeDetector.detectChanges();
                },
                (error) => this._handleWagerStateError()
            );

        this._wagerManager.listenToRaceNavigations()
            .pipe(
                // On the first emission it puts in a 0ms delay to let the view stabilize.
                // Otherwise it causes an issue on mobile because it'll immediately switch
                // from desktop to mobile mode.
                switchMap((s, i) => i === 0 ? of(s).pipe(delay(0)) : of(s))
            )
            .subscribe(
                (raceNav: ITrackBasic) => {
                    // TODO: Right now when a track is selected after no params are
                    // provided on init, the view foundation component is initialized
                    // a second time. This has to do with the navigation that occurs
                    // after the initial race nav object is selected.
                    this._navigationManager.updateTrack(raceNav);
                    this._navigationManager.updateRace(!!raceNav && !!raceNav.RaceNum ? raceNav.RaceNum + '' : ''); // TODO: What about when race not specified?

                    this._raceDetailsHandler.updateRaceNavigation(raceNav);

                    if (raceNav && raceNav.BrisCode) {
                        this._title.setTitlePrefix(raceNav.BrisCode.toUpperCase());
                    } else {
                        this._title.restoreTitle();
                    }
                }
            );

        this._raceDetailsHandler.listen().subscribe(raceDetails => {
            const prevStatus = this.selectedRaceStatus;
            this.selectedRaceStatus = !!raceDetails ? raceDetails.status : null;
            if (prevStatus !== this.selectedRaceStatus) {
                this._changeDetector.detectChanges();
            }
        });

        this._betSlipService.watchBetsToEdit()
            .pipe(
                filter(b => !!b),
                transformBetToWagerState(bet => (bet.track && bet.race)
                ?   this._tracksDataService.todaysRaceBetTypes(
                        bet.track.BrisCode,
                        bet.track.TrackType,
                        bet.race.race,
                        true
                    )
                :   of([])
                ),
                this._conditionallyResumeFirstWager(),
                takeUntil(this._kill)
             )
             .subscribe(
                 ws => this._wagerManager.resumeWager(ws),
                 _e => this._handleWagerStateError()
             );

        combineLatest([this._route.paramMap, this._route.queryParamMap]).pipe(
            debounceTime(100),
            takeUntil(this._kill)
        ).subscribe(([paramMap, queryParamMap]) =>
            this._handleRouteParamUpdate(paramMap, queryParamMap)
        );

        this._splitBetButtonFT = this._featureToggleService.isFeatureToggleOn('SPLIT_BET_BUTTON');

        this._tabletMediaQuery = this._mediaQueryService.registerQuery('tablet-portrait').subscribe((match) => {
            this.isTabletPortrait = match;
            this._changeDetector.markForCheck();
        });
    }

    ngOnDestroy() {
        // Stash the bet in BetSlipBusinessService so it can be resumed later.
        this._betSlipService.currentBet = Bet.fromWagerState(this.wagerState);

        this._kill.next();
        this._kill.complete();
        this.eventStream.complete();
        this._navigationManager.kill();
        this._wagerManager.kill();
        this._raceDetailsHandler.kill();
        this._tabletMediaQuery.unsubscribe();
    }

    /** CONTROLS **/
    public selectView(view: WageringViewEnum) {
        this._navigationManager.updateView(view);
    }

    public onSectionChange(section: ViewSectionEnum) {
        this._navigationManager.updateSection(section);
    }

    public onUpdateRaceNav(raceNav: ITrackBasic) {
        this._stashedEntrySelections = null;
        this._wagerManager.updateRaceNav(raceNav);
    }

    public selectTrackFromList(trackList: ITrackBasic[] | ITrack[]) {
        this._wagerManager.selectTrackFromList(trackList);
    }

    public onSelectBetTypeFromList(betNav: (BasicBetType | MultiRaceExoticBetType)[]) {
        this._wagerManager.selectBetTypeFromList(betNav);
    }

    public onUpdateBetType(betType: BasicBetType | MultiRaceExoticBetType) {
        this._wagerManager.updateBetType(betType);
    }

    public onUpdateBetModifier(betSubType: enumBetModifier) {
        this._wagerManager.updateBetModifier(betSubType);
    }

    public onUpdateBetAmount(betAmount: ISelectedBetAmount) {
        this._wagerManager.updateBetAmount(betAmount);
    }

    public onUpdateEntrySelection(selection: ISelectionUpdate[]) {
        this._wagerManager.updateEntries(selection);
    }

    public onUpdateAlternateSelectionChange(alternates: IAlternateSelections){
         this._wagerManager.updateAlternates(alternates);
    }

    public onResetEntrySelections() {
        this._wagerManager.resetEntries();
    }

    public onStashEntrySelections() {
        this._stashedEntrySelections = this.wagerState?.bettingInterests || [];
        this._wagerManager.resetEntries();
    }

    public onRestoreEntrySelections() {
        if (this._stashedEntrySelections?.length > 0) {
            this.onUpdateEntrySelection(this._stashedEntrySelections.map(
                (entries, leg) => ({ entries, leg, selected: true })
            ));
        }
    }

    public onRegisterEvent(state: WagerEvent) {
        this.eventStream.next(state);
    }

    public onDismissError(tookAction: boolean) {
        this.eventStream.next({type: WagerEventTypeEnum.ERROR_DISMISSED});
    }

    public onTriggerFundingFlow() {
        if (this.ftFullPageDeposit) {
            this._fundingService.postDepositRedirectURL = this._router.url.split('?')[0];
            this._router.navigate(['/', 'deposit']);
        } else {
            this._sidebarService.loadComponent(FundingDepositOptionsComponent.getSidebarComponent(), FundingDepositOptionsComponent.getHeaderComponent(), {clearHistory: true});
        }
        this._fundingService.updateAccountBalance().pipe(take(1)).subscribe();
    }

    public onWagerSubmitted(message: string) {
        this.showSuccessfulMessage(message);
    }

    public onWagerSaved() {
        // TBD
    }
    /** END CONTROLS **/

    /**
     * Triggers handling for an update in route params.
     *
     * @param params
     */
    private _handleRouteParamUpdate(params: ParamMap, queryParams: ParamMap) {

        const isValidView = (view: any): boolean => Object.keys(WageringViewEnum).map((v) => WageringViewEnum[v]).includes(view);
        const isValidSection = (section: any): boolean => Object.keys(ViewSectionEnum).map((v) => ViewSectionEnum[v]).includes(section);

        if (params.has('view') && isValidView(params.get('view'))) {
            this.selectView(params.get('view') as WageringViewEnum);
        } else {
            this.selectView(this.defaultView);
        }

        if (params.has('section') && isValidSection(params.get('section'))) {
            this.onSectionChange(params.get('section') as ViewSectionEnum);
        } else {
            this.onSectionChange(this._viewStateService.getViewSectionCache() as ViewSectionEnum);
        }

        if (params.get('section') === ViewSectionEnum.VIDEO) {
            if (params.get('view') === WageringViewEnum.CLASSIC) {
                this.selectView(WageringViewEnum.VIDEO);
            }
            // do not re-select video, that is not a valid section, just use basic instead
            const lastView: ViewSectionEnum = this._viewStateService.getViewSectionCache();
            this.onSectionChange(lastView !== ViewSectionEnum.VIDEO ? lastView :
                ViewStateService.DEFAULT_VIEW_STATE[ViewStateGroupEnum.PROGRAM].section);
        }

        if (params.has('track') && params.has('type') && params.has('trackName')) {
            const track = <ITrackBasic> {
                BrisCode: params.get('track'),
                TrackType: params.get('type')
            };
            if (params.has('race')) {
                track.RaceNum = +params.get('race');
            }
            if (params.has('trackName')) {
                track.DisplayName = params.get('trackName')
            }
            if (!this.wagerState || !this.wagerState.basicTrack ||
                !TrackService.isSameTrack(track, this.wagerState.basicTrack) ||
                (track.RaceNum && track.RaceNum !== this.wagerState.basicTrack.RaceNum)) {
                this.onUpdateRaceNav(track);
            }

            if (queryParams.has('quickbetdata')) {
                try {
                    const quickBet = QuickBetLinkUtil.buildQuickBetModel(JSON.parse(queryParams.get('quickbetdata')),
                        { brisCode: track.BrisCode, trackType: track.TrackType, raceNumber: track.RaceNum }
                    ), quickBetAmount = quickBet.amount.value;

                    this._wagerService.buildBetForPool(track, quickBet.poolType.Code, quickBet).subscribe((bet) => {
                        if (!bet.poolType || !bet.poolType.Name || bet.poolType.Code !== quickBet.poolType.Code) {
                            throw new Error('Invalid or closed wagering pool: ' + quickBet.poolType.Code);
                        }
                        bet.runners = quickBet.runners; // TODO: buildBetForPool resets runners, make it switchable?
                        bet.amount.value = quickBetAmount; // initial bet amount is overwritten with the base amount
                        bet.amount.type = BetAmountType.CUSTOM; // not sure if quick bet amount matches provided one
                        this._betSlipService.editBet(bet);
                    });
                } catch (e) {
                    console.error('Invalid quick bet data: ' + queryParams.get('quickbetdata'));
                }
            }
        } else {
            // Must update controls with a null initial race nav object
            // to allow completion of race nav state initialization.
            this.onUpdateRaceNav(null);
        }

        this._changeDetector.detectChanges();
    }

    /**
     * An error is thrown when a restricted view is selected.
     * Decide what we do about it here.
     */
    private _handleRestrictedNavigation(error: Error | RestrictedViewError) {
        if (!this._jwtSessionService.isLoggedIn()) {
            const routeUrl = this._router.parseUrl(this._router.url);
            if ('view' in error) {
                // kinda hacky, but we need to generate the intended path
                if (routeUrl.root.children.primary.segments.length > 1) {
                    routeUrl.root.children.primary.segments[1].path = error.view;
                }
            }
            this._jwtSessionService.redirectLoggedInUserUrl = this._router.serializeUrl(routeUrl);
            this._router.navigate([ LOGIN_REDIRECT_PATH ], { replaceUrl: true });
        } else {
            this._router.navigate([ DEFAULT_REDIRECT_PATH ]);
        }
    }

    /**
     * An error is thrown when an invalid initial track is provided. Handle
     * the error here.
     */
    private _handleWagerStateError() {
        // TODO: Handle error with wager state. e.g. user attempts
        // to navigate to restricted track.
    }

    private _handleWagerSubmissionError(message: string, error?: IBetError) {
        this.error = {
            message: message
        };
        if (error && (
            error.errorCode === BET_ERROR_CODES.INSUFFICIENT_FUNDS ||
            error.errorCode === BET_ERROR_CODES.INSUFFICIENT_FUNDS_QUICKBET
        )) {
            this.error.button = this._translateService.translate('deposit-text', 'wagers');
            this.error.action = () => this.onTriggerFundingFlow();
        }
    }

    private showSuccessfulMessage(message: string) {
        // show successful message
        switch (this.selectedView) {
            case WageringViewEnum.MOBILE:
                this._toast.open('Your bet has been placed', {
                    panelClass: 'action-success',
                    duration: 3000,
                    action: 'VIEW MY BETS',
                    actionFn: () => {
                        if (this._splitBetButtonFT) {
                            this._sidebarService.loadComponent(
                                TodaysBetsContainerComponent.getSidebarComponent(),
                                null,
                                { clearHistory: true }
                            )
                        } else {
                            this._sidebarService.loadComponent(
                                BetsContainerComponent.getSidebarComponent({currentBetsView: BetsViewEnum.ACTIVE_BETS}),
                                BetsContainerComponent.getHeaderComponent(),
                                { clearHistory: true }
                            );
                        }
                    }
                });
                break;
            default:
                const options = {
                    displayTime: this.SUCCESS_PANEL_DISPLAY_LENGTH,
                    message: message,
                    navTarget: this._splitBetButtonFT ? MenuItemsEnum.TODAYS_BETS : MenuItemsEnum.BET_SLIP
                };
                this._sidebarService.loadComponent(BetSuccessPanelComponent.getSidebarComponent(options), null, {
                    clearHistory: true,
                    isSuccess: true
                });
        }
    }

    private _conditionallyResumeFirstWager() {
        return (src: Observable<WagerState>) => src
            .pipe(
                // If there's no emission to start with, then we need to add one for the
                // later switch map to run. It HAS to run on startup.
                !this._betSlipService.currentBet ? startWith(null) : (s) => s,
                withLatestFrom(this._route.paramMap),
                switchMap(([wagerState, params]: [WagerState, ParamMap], index) => {
                    // On the first emission, we want to check if the track and race match what the
                    // params say. If they don't match, then it's pretty safe to assume the user
                    // wasn't trying to resume where they left off.
                    if (index === 0
                        && this._betSlipService.currentBet
                        && this._betSlipService.currentBet.track
                        && (
                            wagerState.basicTrack.BrisCode !== params.get('track')
                            || wagerState.basicTrack.TrackType !== params.get('type')
                            || wagerState.basicTrack.RaceNum !== +params.get('race')
                        )
                        || !wagerState) {
                        return NEVER;
                    } else {
                        return of(wagerState);
                    }
                }),
            );
    }
}
