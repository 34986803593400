import { Inject, Injectable } from '@angular/core';
import { catchError, distinctUntilChanged, share, take, tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

import { ENVIRONMENT, ENVIRONMENTS } from '@cdux/ng-core';
import { CduxStorageService } from '@cdux/ng-platform/web';
import {
    CduxSettingService,
    CduxConsoleService,
    ConfigurationDataService,
    DetectionService,
    enumConfigurationStacks,
    enumFeatureToggle,
    EventClickAttributeType,
    EventClickType,
    EventSessionBusinessService,
    EventTrackingType,
    FavoriteRunnersService,
    FavoriteTracksService,
    FeatureToggleDataService,
    JwtSessionService,
    LocationService,
    MultiSessionService,
    PlatformService,
    ToteDataService,
    FavoritePersonService
} from '@cdux/ng-common';
import { BrazeUtils } from '@cdux/ts-domain-braze';

import { CduxTitleService } from 'app/shared/common/services/title/title.service';
import { EAccountNotificationBadge } from 'app/shared/notification/badges/account-notification-badge.enum';
import { FavoritesManagerRunnersComponent } from 'app/account/favorites/manager-runners/favorites-manager-runners.component';
import { NoncurrentRacesConfigService } from 'app/shared/program/services/noncurrent-races-config.service';
import { NotificationBadgeService } from 'app/shared/notification/badges/notification-badge.service';
import { FavEventType, FavEventMessage } from 'app/account/favorites/favorites-event-interface';
import { AccountBubbleNotificationService } from 'app/shared/notification/services/account-bubble-notification.service';
import { TodaysRacesFilterInfo } from 'app/shared/program/models/todays-races-filter-info';
import { ZendeskChatService } from '@cdux/ng-fragments';

@Injectable()
export class ApplicationInitializationService {
    /**
     * maximum time to allow for an initialization step to complete, in milliseconds
     *
     * @type {number}
     */
    public static TIMEOUT = 10000;

    private static hasInitialized = false;

    private showNotification = false;

    private _configurationKeys = {
        gtm_event: 'gtm_environment',
        brazeApiKey: 'brazeApiKey',
        brazeSdkEndpoint: 'brazeSdkEndpoint',
        brazeScopeUrl: 'brazeScopeUrl',
        brazeScriptUrl: 'brazeScriptUrl',
        brazeSafariWebsitePushId: 'brazeSafariWebsitePushId',
        brazeSessionTimeoutInSeconds: 'brazeSessionTimeoutInSeconds',
        brazeEnableSdkAuthentication: 'brazeEnableSdkAuthentication'
    }

    constructor(
        private environment: ENVIRONMENT,
        /*
         * Injecting CduxConsoleService overrides console with methods
         * that only output at or above the set level. The default level
         * is ERROR.
         */
        private cduxSettingService: CduxSettingService,
        private consoleService: CduxConsoleService,
        private cduxStorageService: CduxStorageService,
        private detectionService: DetectionService,
        private eventSessionBusinessService: EventSessionBusinessService,
        private favoriteRunnersService: FavoriteRunnersService,
        private favoriteTracksService: FavoriteTracksService,
        private favoritePersonService: FavoritePersonService,
        private featureToggleService: FeatureToggleDataService,
        private locationService: LocationService,
        private notificationBadgeService: NotificationBadgeService<EAccountNotificationBadge>,
        private notificationBubbleService: AccountBubbleNotificationService,
        private multiSessionService: MultiSessionService,
        private noncurrentRacesConfigService: NoncurrentRacesConfigService,
        private sessionService: JwtSessionService,
        private platformService: PlatformService,
        private title: CduxTitleService,
        private tote: ToteDataService,
        private configurationService: ConfigurationDataService,
        private zendeskChatService: ZendeskChatService,
        @Inject(BrazeUtils) private _brazeUtils: BrazeUtils,
    ) { }

    public initialize(): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            if (ApplicationInitializationService.hasInitialized) {
                return reject('Application has already been initialized.');
            } else {
                resolve();
            }
        })
        /*
         * Individual .then()s will be resolved serially (blocking).
         * Promise.all() will resolve the grouped operations in parallel*
         * within the serial sequence of its containing .then().
         *
         * *The operations are _initiated_ in sequence, so there may be
         * some benefit to ordering. For example, starting longer calls
         * first may have them finishing by the time the shorter requests
         * at the end of the list are in flight.
         *
         *
         * N.B.: When adding a promise that relies on a BehaviorSubject, you
         * MUST use first() or take(), instead of asObservable(), before
         * calling toPromise(). For more information, see
         * https://github.com/Reactive-Extensions/RxJS/issues/1088
         *
         * IE11 does not support ES6 Promises. Polyfills come from core-js.
         * https://github.com/zloirock/core-js/blob/e778e8026aed8a58f93f1ee4e3192cd1a7d7bdf5/modules/es6.promise.js
         */
        .then(() => this.cduxSettingService.isInitialized.toPromise())
        .then(() => this.consoleService.isInitialized.toPromise())
        .then(() => this.loadFeatureToggles())
        .then(() =>
            Promise.all([
                this.logDeviceSessionData(),
                this.loadUserFavoritesOnLogin(),
                this.loadNoncurrentRacesConfigServiceOnLogin(),
                this.requestCurrentPositionOnLogin(),
                this.resetStoredDataOnAuthChange(),
                this.reportMultiSessionCount(),
                this.updateTitleForLocalDev(),
                this.initializeMarketingTools(),
                this.initializeZendeskMessenger(),
            ])
        )

        // final step
        .then(() => ApplicationInitializationService.hasInitialized = true);
    }

    /**
     * replaces title token for local builds
     *
     * Since local builds are run differently than environment builds,
     * the token replacement for the title doesn't happen.
     *
     * Doing this in production code just for development is, obviously,
     * a very low priority.
     */
    private updateTitleForLocalDev() {
        if (this.environment.environment === ENVIRONMENTS.LOCAL &&
            this.title.getBaseTitle() === '<!-- APPLICATION_TITLE -->') {
            this.title.setBaseTitle(this.environment.domain + ' | LOCAL');
        }
    }

    private loadFeatureToggles(): Promise<any> {
        // Setup Feature Toggle Polling
        const featureTogglePolling = this.featureToggleService.pollFeatureToggles().pipe(
            share()
        );
        // Make Observable Hot
        featureTogglePolling.subscribe();
        // Return Converted Promise
        return featureTogglePolling.pipe(
            take(1)
        ).toPromise();
    }

    private reportMultiSessionCount(): Promise<any> {
        return new Promise(resolve => {
            const count = this.multiSessionService.getActiveSessionCount();

            if (count > 1) {
                // TODO: using click event because its easy and this is (probably) temporary,
                // we should find some better way if this stays around as a permanent feature
                this.eventSessionBusinessService.logEvent(EventTrackingType.CLICK, {
                    clickEventId: EventClickType.SESSION_OPEN_ADDITIONAL, attributes: [{
                        attrId: EventClickAttributeType.SESSION_OPEN_ADDITIONAL_SESSION_COUNT,
                        data: count
                    }],
                    timestamp: Date.now()
                });
            }

            resolve(count);
        });
    }

    private logDeviceSessionData(): void {
        if (this.environment.environment !== ENVIRONMENTS.LOCAL && !this.platformService.isNativeApp()) {
            try {
                const sessionData = this.eventSessionBusinessService.getDeviceSessionData();
                // DeviceJS is responsible for getting the affiliate ID in some other repositories, such as TSUI.
                // Given that DeviceJS can't find the affiliate ID in the same way on TUX, we are manually adding it here.
                if (!sessionData.affiliateId || (sessionData.affiliateId && sessionData.affiliateId.trim() === '')) {
                    sessionData.affiliateId = this.environment.affiliateId;
                }
                this.eventSessionBusinessService.logEvent(
                    EventTrackingType.SESSION_CACHE, sessionData
                );
            } catch (error) {
                console.warn('Session Cache request failed.', error);
            }
        }
    }

    private loadNoncurrentRacesConfigServiceOnLogin(): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            this.noncurrentRacesConfigService.loadConfig().subscribe(
                (result) => { resolve(); },
                (reason) => { reject(); }
            );
        });
    }

    private loadUserFavoritesOnLogin(): Promise<any> {
        const loaderFactory = (load = this.sessionService.isLoggedIn()) => {
            return new Promise<void>((resolve, reject) => {
                if (load) {
                    /**
                     * build list of favorites to load
                     *
                     * Any loading calls that are made conditionally
                     * (whether as part of a permanent feature toggle or a
                     * "roll-out" one), can be added to this array. Notice
                     * the methods are invoked, so the array actually holds
                     * their returned promises. These are then added to the
                     * Promise.all, below.
                     *
                     * If list items become non-conditional, move them
                     * directly into the Promise.all call.
                     */
                    const featureToggledPromises: Array<Promise<any>> = [];
                    if ( this.featureToggleService.isFeatureToggleOn(enumFeatureToggle.STABLE_ALERTS) ) {
                        featureToggledPromises.push(this.favoriteRunnersService.load('runner'));
                        featureToggledPromises.push(this.favoritePersonService.load('person'));
                    }

                    const timer = setTimeout(resolve, ApplicationInitializationService.TIMEOUT);

                    // Promise.allSettled isn't available with our polyfills (or whatever)
                    Promise.all([
                        this.favoriteTracksService.loadFavoriteTracks(this.sessionService.getUserInfo()),
                        ...featureToggledPromises
                    ])
                        .then(result => {
                            /**
                             * Determine if the user has any favorite runners
                             * running today. If so, mark for a notification
                             * badge UNLESS the user has already dismissed the
                             * badge and is still on the same tote date.
                             */
                            // if the user has favorites...
                            //This boolean is here to make sure we don't show the same notification twice if they have both runners and trainers/jockeys running today.
                            this.favoriteRunnersService.hasRunnersToday().then(
                                hasRunnersToday => {
                                    // USER-4209 (new user first time login also get fav notification badge) fix:
                                    // check following only when user truly has fav runner today:
                                    if (hasRunnersToday) {
                                        // ... look for a last-seen tote date
                                        const userInfo = this.sessionService.getUserInfo();
                                        const lastToteDate = localStorage.getItem(FavoritesManagerRunnersComponent.KEY_LAST_SEEN_TOTE_DATE + userInfo.camId);

                                        // ... if there isn't one, add notification
                                        if (!lastToteDate) {
                                            this.showNotification = true;
                                            this.notifyUserOfFavoritesRunningToday();
                                        } else {
                                            this.tote.currentRaceDate().toPromise().then(
                                                toteDate => {
                                                    // or if the date isn't today
                                                    // (we'll assume that means it's in the past, to keep the comparison simple)
                                                    if (lastToteDate !== toteDate) {
                                                        localStorage.removeItem(FavoritesManagerRunnersComponent.KEY_LAST_SEEN_TOTE_DATE + userInfo.camId);
                                                        this.showNotification = true;
                                                        this.notifyUserOfFavoritesRunningToday();
                                                    }
                                                }
                                            );
                                        }
                                        // otherwise, he's already dismissed the notification today
                                    } else if (!hasRunnersToday || !this.showNotification) {
                                        this.favoritePersonService.hasRunnersToday().then(
                                            hasRunnersToday => {
                                                if (hasRunnersToday) {
                                                    const userInfo = this.sessionService.getUserInfo();
                                                    const lastToteDate = localStorage.getItem(FavoritesManagerRunnersComponent.KEY_LAST_SEEN_TOTE_DATE + userInfo.camId);

                                                    if (!lastToteDate && !this.showNotification) {
                                                        this.notifyUserOfFavoritesRunningToday();
                                                    } else {
                                                        this.tote.currentRaceDate().toPromise().then(
                                                            toteDate => {
                                                                // or if the date isn't today
                                                                // (we'll assume that means it's in the past, to keep the comparison simple)
                                                                if (lastToteDate !== toteDate && !this.showNotification) {
                                                                    localStorage.removeItem(FavoritesManagerRunnersComponent.KEY_LAST_SEEN_TOTE_DATE + userInfo.camId);
                                                                    this.notifyUserOfFavoritesRunningToday();
                                                                }
                                                            }
                                                        );
                                                    }
                                                }
                                            }
                                        );
                                    }
                                }
                            );
                        })
                        .then(resolve)
                        .catch(reject)
                        .then(() => clearTimeout(timer));
                } else {
                    this.favoriteTracksService.clearFavoriteTracks();
                    this.favoriteRunnersService.clear();
                    resolve();
                }
            });
        };

        // add login task to delay login process until loaded
        this.sessionService.addLoginTask(loaderFactory, true);

        this.sessionService.addLoginTask(async () => await this.zendeskChatService.loginZeMessenger(), false);

        // add logout task to clear loaded fav data at logout
        this.sessionService.addLogoutTask(() => loaderFactory(false), true);

        // add logout task to logout of zendesk chat
        this.sessionService.addLogoutTask(async () => await this.zendeskChatService.logoutZeMessenger(), false)

        //add logout task to unsubscribe from braze in app messages and content cards
        this.sessionService.addLogoutTask(async () =>  await this._brazeUtils.unsubscribeFromContentCards(), false);
        this.sessionService.addLogoutTask(async () =>  await this._brazeUtils.unsubscribeFromInAppMessages(), false);

        // load or clear on init based on initial auth status
        return loaderFactory();
    }

    private notifyUserOfFavoritesRunningToday() {
        this.notificationBadgeService.add(EAccountNotificationBadge.FAVORITE_RUNNER_TODAY);
        this.notificationBubbleService.delay({
            type: FavEventType.FAVORITE_RUNNING_TODAY,
            payload: {
                message: FavEventMessage.FAVORITE_RUNNING_TODAY,
                path: '/favorites/manager',
            }
        }, 3000);
    }

    private requestCurrentPositionOnLogin(): void {
        this.sessionService.addLoginTask(() => new Promise<void>((resolve, reject) => {
            if (this.sessionService.isLoggedIn() &&
                this.sessionService.getUserInfo().wagerGeoCheck &&
                this.detectionService.isMobileDevice()) {
                this.locationService.getCurrentPosition({
                    timeout: ApplicationInitializationService.TIMEOUT
                }).then(() => resolve(), () => resolve()); // always resolve to proceed
            } else {
                resolve(); // location not required, resolve to proceed
            }
        }), true);
    }

    private resetStoredDataOnAuthChange(): void {
        this.sessionService.onAuthenticationChange.pipe(
            distinctUntilChanged()
        ).subscribe(() => {
            // Store a null filter instead of destroying. Destroying
            // results in an error emitted to database observers
            this.cduxStorageService.store(new TodaysRacesFilterInfo(null));
        });
    }

    private initializeMarketingTools(): Promise<any> {
        const configKeys = [];
        if ( !this.platformService.isNativeApp()) {
            if (this.featureToggleService.isFeatureToggleOn(enumFeatureToggle.GTM)) {
                configKeys.push(this._configurationKeys.gtm_event);
            }

            if (this.featureToggleService.isFeatureToggleOn(enumFeatureToggle.BRAZE)) {
                configKeys.push(
                    this._configurationKeys.brazeApiKey,
                    this._configurationKeys.brazeSdkEndpoint,
                    this._configurationKeys.brazeScopeUrl,
                    this._configurationKeys.brazeScriptUrl,
                    this._configurationKeys.brazeSafariWebsitePushId,
                    this._configurationKeys.brazeSessionTimeoutInSeconds,
                    this._configurationKeys.brazeEnableSdkAuthentication
                );
            }
        }

        if (configKeys.length > 0) {
            return this.configurationService.getConfiguration(enumConfigurationStacks.TUX, configKeys)
                .pipe(
                    take(1),
                    tap(config => {
                        if (this.sessionService.isLoggedIn()) {
                            this._initializeBraze(config);
                        } else {
                            this.sessionService.addLoginTask(() => new Promise<void>((resolve) => {
                                this._initializeBraze(config);
                                resolve();
                                // don't block login, catch any error silently and continue
                            }).catch(err => { }), false);
                        }
                    }),
                    // catch error and resolve, we don't want to block app load for marketing tools
                    catchError(() => EMPTY)
                ).toPromise();
        }
        return new Promise<void>((resolve) => resolve());
    }

    private _initializeBraze(config: { [key: string]: string }) {
        if (config && config[this._configurationKeys.brazeApiKey] && config[this._configurationKeys.brazeSdkEndpoint] && config[this._configurationKeys.brazeSafariWebsitePushId]) {
            // Braze config to override style conflicts with Mint-Julep
            const brazeConfig = {
                htmlId: 'tuxBrazeContainer',
                css: 'body #tuxBrazeContainer button { min-width: 0 }'
            }
            const brazeParams = {
                baseUrl: config[this._configurationKeys.brazeSdkEndpoint],
                safariWebsitePushId: config[this._configurationKeys.brazeSafariWebsitePushId],
                manageServiceWorkerExternally: true,
                sessionTimeoutInSeconds: +config[this._configurationKeys.brazeSessionTimeoutInSeconds] || null,
                enableSdkAuthentication: config[this._configurationKeys.brazeEnableSdkAuthentication] === 'true'
            }
            this._brazeUtils.initializeBraze(config[this._configurationKeys.brazeApiKey], brazeParams, brazeConfig);
        }

        if (config && config[this._configurationKeys.brazeScopeUrl] && config[this._configurationKeys.brazeScopeUrl]) {
            const brazeScriptUrl = this.environment.environment === ENVIRONMENTS.LOCAL ? '/braze.service-worker.js' : config.brazeScriptUrl;
            const brazeScopeUrl = this.environment.environment === ENVIRONMENTS.LOCAL ? '/' : config.brazeScopeUrl;
            navigator.serviceWorker.register(brazeScriptUrl, {scope: brazeScopeUrl})
                .catch((err)  => {
                    console.error('Error registering service worker');
                });
        }
    }

    private initializeZendeskMessenger() {
        this.zendeskChatService.launchZeMessenger();
    }
}
