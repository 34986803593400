import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactory,
    ComponentFactoryResolver,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { formatNumber, Location } from '@angular/common';
import { Router } from '@angular/router';

import {
    AnglesMarks,
    enumFeatureToggle,
    enumTrackType,
    EventClickType,
    FeatureToggleDataService,
    IOddsTrend,
    IOddsTrendDetails,
    ISummaryStats,
    ITrackBasic,
    ITrainerJockeyEntrySummary,
    ITrainerDriverEntrySummary,
    ProgramEntry,
    StringSlugifyPipe,
    TrackService,
    WagerState,
    ISummaryRunnerStats
} from '@cdux/ng-common';

import { BreadcrumbsService } from 'app/shared/breadcrumbs';
import { Rankings } from '../program/program.component';
import { ViewSectionEnum } from 'app/wagering/views/enums/view-section.enum';
import { IViewableWagerData } from 'app/shared/betpad/interfaces/viewable-wager-data.interface';
import { WageringViewEnum } from 'app/wagering/views/enums/wagering-view.enum';
import { IDetails, IDetailsOptions } from 'app/shared/program/interfaces/details.interface';
import { enumDetailsType } from 'app/shared/program/enums/details-types.enum';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { DriverDetailsComponent } from 'app/shared/program/components/driver-details/driver-details.component';
import { RunnerDetailsComponent } from 'app/shared/program/components/runner-details/runner-details.component';
import { GreyhoundDetailsComponent } from 'app/shared/program/components/runner-details/greyhound-details.component';
import { TrainerJockeyDetailsComponent } from 'app/shared/program/components/trainer-jockey-details/trainer-jockey-details.component';
import { SireDetailsComponent } from 'app/shared/program/components/ancestor-details/sire-details.component';
import { DamDetailsComponent } from 'app/shared/program/components/ancestor-details/dam-details.component';
import { TrainerDetailsComponent } from 'app/shared/program/components/trainer-details/trainer-details.component';
import { SummaryDetailsComponent } from '../summary-details/summary-details.component';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';
import { ISummaryRunnerTopRanks } from '../program-summary/program-summary-runner-ranks.interface';

@Component({
    selector: 'cdux-program-entry',
    templateUrl: './program-entry.component.html',
    styleUrls: ['./program-entry.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgramEntryComponent<T extends ProgramEntry = ProgramEntry> {
    public enumDetailsType = enumDetailsType;
    private _entry: T;
    @Input()
    public set entry(newEntry: T) {
        if (newEntry) {
            const isNewEntry: boolean = !this._entry ||
                this._entry.EntryId !== newEntry.EntryId ||
                this._entry.ProgramNumber !== newEntry.ProgramNumber;

            this._entry = newEntry;
            this.trainerName = this.joinNames(newEntry.TrainerFirstName, newEntry.TrainerMiddleName, newEntry.TrainerLastName);
            this.jockeyName = this.joinNames(newEntry.JockeyFirstName, newEntry.JockeyMiddleName, newEntry.JockeyLastName);
            this.advancedRunnerInfo = this.formatAdvancedRunnerInfo(newEntry);
            this.runStyle = this.formatRunStyle(newEntry);

            this.oddsTrendArray = this.getOddsTrendArray(newEntry.OddsTrend);
            if (isNewEntry || this.oddsTrendArray.length === 0) {
                this.isShowingOddsTrend = false;
            }
            if (this.showingDetailsType === enumDetailsType.RUNNER && this.isEntrySummaryEnabled === true) {
                this.setSummaryDetails();
            }
            this._changeDetector.detectChanges();
        }
    }
    public get entry(): T {
        return this._entry;
    }

    private _trainerJockeySummary: ITrainerJockeyEntrySummary;
    private _trainerDriverSummary: ITrainerDriverEntrySummary;
    private _summaryRunnerStats: ISummaryRunnerStats;
    private _currentYear = new Date().getFullYear();
    public runnerTopRanks: ISummaryRunnerTopRanks =
        {
            avgSpeed: 0,
            backSpeed: 0,
            speedLastRace: 0,
            averageClass: 0,
            primePower: 0,
            jockeyWinPercent: 0,
            trainerWinPercent: 0,
            totalEarnings: 0
        }
    @Input()
    public set trainerJockeySummary(summary: ITrainerJockeyEntrySummary) {
        this._trainerJockeySummary = summary;
        if (summary) {
            this.trainerSummary = this.formatTrainerSummary(summary);
            this.jockeySummary = this.formatJockeySummary(summary);
            this.jockeyWinPct = summary ? summary.jockeyWinPct : null;
            this.trainerWinPct = summary ? summary.trainerWinPct : null;
        }
    }
    public get trainerJockeySummary(): ITrainerJockeyEntrySummary {
        return this._trainerJockeySummary;
    }
    @Input()
    public set trainerDriverSummary(summary: ITrainerDriverEntrySummary) {
        this._trainerDriverSummary = summary;
        if (summary) {
            const trainerStats = summary.trainerStats?.summaryStats?.find((s: any) => s.statsType === 'last365AllTracks');
            const driverStats = summary.driverStats?.summaryStats?.find((s: any) => s.statsType === 'last365AllTracks');
            this.trainerSummary = this.formatTrainerDriverSummary(trainerStats);
            this.jockeySummary = this.formatTrainerDriverSummary(driverStats);
            this.jockeyWinPct = driverStats ? (driverStats.winPercent || 0) / 100 : null;
            this.trainerWinPct = trainerStats ? (trainerStats.winPercent || 0) / 100 : null;
        }
    }
    public get trainerDriverSummary(): ITrainerDriverEntrySummary {
        return this._trainerDriverSummary;
    }

    private _summaryRunnerStatsMap: Map<string, ISummaryRunnerStats> = new Map();

    @Input()
    public set summaryRunnerStats(stats: ISummaryRunnerStats) {
        if (stats) {
            stats.averageClass = Math.round(stats.averageClass);
            stats.primePower = Math.round(stats.primePower);
            this.formatZeroValues(stats);
            if (stats.postPosition) {
                this._summaryRunnerStatsMap.set(stats.postPosition.toString(), stats);
            }
        }
        this._summaryRunnerStats = stats;
    }

    @Input()
    public set summaryRunnerTopRanks(topRanks: ISummaryRunnerTopRanks) {
        if (topRanks) {
            this.runnerTopRanks = topRanks;
            this.runnerTopRanks.averageClass = Math.round(topRanks.averageClass);
            this.runnerTopRanks.primePower = Math.round(topRanks.primePower);
        }
    }

    public get summaryRunnerStats(): ISummaryRunnerStats {
        return this._summaryRunnerStats;
    }

    // Add a new method to get all stats
    public getAllSummaryRunnerStats(): ISummaryRunnerStats[] {
        return Array.from(this._summaryRunnerStatsMap.values());
    }

    private formatZeroValues(stats: ISummaryRunnerStats) {
        if (stats.averageClass === 0) {
            stats.averageClass = null;
        }
        if (stats.primePower === 0) {
            stats.primePower = null;
        }
    }

    @Output()
    summaryRunnerSort = new EventEmitter<ISummaryRunnerStats>();
    
    @Input()
    public rankings: Rankings;

    @Input()
    public angles: AnglesMarks;

    @Input()
    public viewableWagerData: IViewableWagerData;

    @Input()
    public set wagerState(wagerState: WagerState) {
        if (wagerState) {
            // Handle closing details on track change
            if (wagerState.basicTrack && this._wagerState && !TrackService.isExactTrackObject(wagerState.basicTrack, this._wagerState.basicTrack)) {
                this._toggleDetailsContainer(null);
                this.isShowingOddsTrend = false;
            }

            this._wagerState = wagerState;
        }
    }
    public get wagerState(): WagerState {
        return this._wagerState;
    }
    private _wagerState: WagerState;

    @Input() public isTbredTrack: boolean;
    @Input() public isGreyTrack: boolean;
    @Input() public isHarnessTrack: boolean;
    @Input() public isNorthAmericanTrack: boolean;
    @Input() public isCompact: boolean;
    @Input() public isBasicClass: boolean = false;
    @Input() public sectionClass: string = '';
    @Input() public checkioClass: string;
    @Input() public displaySilk: boolean;
    @Input() public showExpertPick: boolean = false;
    @Input()
    public set section(section: ViewSectionEnum) {
        // Close the details if we switch to not advanced
        if (section && section !== this._section) {
            this._toggleDetailsContainer(null);
            this.isShowingOddsTrend = false;
        }
        this._section = section;
    }
    public get section(): ViewSectionEnum {
        return this._section;
    }
    private _section: ViewSectionEnum;

    @Input()
    public set isFavoriteRunner(isFavoriteRunner: boolean) {
        this._isFavoriteRunner = isFavoriteRunner;
    }
    public get isFavoriteRunner() {
        return this._isFavoriteRunner;
    }
    private _isFavoriteRunner: boolean;

    @Input() isFavoriteTrainer: boolean;

    @Input() isFavoriteJockey: boolean;

    @Input() alternatePickMode: boolean;

    @Input()
    public set activeLeg(activeLeg: number) {
        if (typeof activeLeg === 'string') {
            activeLeg = parseInt(activeLeg, 10);
        }

        if (activeLeg !== this._activeLeg) {
            this._toggleDetailsContainer(null);
            this.isShowingOddsTrend = false;
        }

        this._activeLeg = activeLeg;
    }
    public get activeLeg() {
        return this._activeLeg;
    }
    private _activeLeg = 0;

    @Input() public view: WageringViewEnum;
    @Input() public track: ITrackBasic;

    @ViewChild('detailsContainer', { read: ViewContainerRef })
    private _detailsContainer: ViewContainerRef;

    public trainerName: string;
    public trainerSummary: string;
    public jockeyName: string;
    public jockeySummary: string;
    public jockeyChange: boolean;
    public advancedRunnerInfo: string;
    public trainerWinPct: number;
    public jockeyWinPct: number;
    public runStyle: string;
    public ViewSectionEnum = ViewSectionEnum;
    public WageringViewEnum = WageringViewEnum;
    public showingDetailsType: enumDetailsType;
    public averageClass: number;
    public primePower: number;
    public jockeyWinPercent: number;
    public trainerWinPercent: number;

    public isShowingOddsTrend: boolean = false;
    public isOddsTrendEnabled: boolean = false;
    public oddsTrendArray: IOddsTrendDetails[];
    public isEntrySummaryEnabled: boolean = false;
    public isProfitlineOddsEnabled: boolean = false;
    public isSmallGlass: boolean = false;

    public isDesktop: boolean = false;

    public isExpertPicksEnabled = false;
    public detailsComponent: IDetails;

    constructor(
        private _changeDetector: ChangeDetectorRef,
        private _location: Location,
        private _router: Router,
        private _featureToggleService: FeatureToggleDataService,
        private _stringSlugify: StringSlugifyPipe,
        private _breadcrumbsService: BreadcrumbsService,
        private _mediaQuery: CduxMediaToggleService,
        private _componentFactoryResolver: ComponentFactoryResolver,
        private _eventTrackingService: EventTrackingService
    ) {
        this.isOddsTrendEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.ODDS_TREND);
        this.isExpertPicksEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.EXPERT_PICKS);
        this.isEntrySummaryEnabled = this._featureToggleService.isFeatureToggleOn('ENTRY_SUMMARY');
        this.isProfitlineOddsEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.PROFITLINE_ODDS);
        this.isSmallGlass = this._mediaQuery.query('phone');
    }

    public formatRunStyle(entry: T): string {
        return [
            entry.PriorRunStyle ? entry.PriorRunStyle : 'NA',
            entry.SpeedPoints
        ].join(' ').trim();
    }

    public formatAdvancedRunnerInfo(entry: T): string {
        return entry && [ // Color, Sex, Age yrs (STATE) Medication
            entry.ColorDescription || entry.Color, entry.Sex,
            +entry.Yob && (this._currentYear - +entry.Yob) + ' yrs'
        ].filter(v => !!v).join(', ') + // omit empty strings
            (entry.WhereBred ? ' (' + entry.WhereBred.toUpperCase() + ') ' : '') +
            (entry.FormattedMedication ? ' ' + entry.FormattedMedication : '') + (entry.Blinkers ? ' ' + entry.Blinkers : '')
            || '';
    }

    public formatJockeySummary(summary: ITrainerJockeyEntrySummary): string {
        return summary && [
            summary.jockeyStarts || 0,
            summary.jockeyWins || 0,
            summary.jockeyPlaces || 0,
            summary.jockeyShows || 0
        ].join(' - ');
    }

    public formatTrainerSummary(summary: ITrainerJockeyEntrySummary): string {
        return summary && [
            summary.trainerStarts || 0,
            summary.trainerWins || 0,
            summary.trainerPlaces || 0,
            summary.trainerShows || 0
        ].join(' - ');
    }

    public formatTrainerDriverSummary(summary: ISummaryStats): string {
        if (!summary) { return ''; }
        return `(${formatNumber(summary.starts || 0, 'en-US', '1.0')} starts)`;
    }

    public getOddsTrendArray(oddsTrend: IOddsTrend) {
        return !oddsTrend ? [] : [
            oddsTrend.odds5,
            oddsTrend.odds4,
            oddsTrend.odds3,
            oddsTrend.odds2,
            oddsTrend.current
        ].filter(e => this.areOddsTrending(e));
    }

    public hasRunnerDetails(): boolean {
        return !!(this.isNorthAmericanTrack && this.entry && (
            (this.isTbredTrack && this.entry.EntryId > 0) ||
            (this.isGreyTrack && this.entry.ProgramNumber) ||
            (this.viewableWagerData && this.viewableWagerData.runnerStats)
        ));
    }

    public hasJockeyDetails(): boolean {
        return (this.isTbredTrack &&
            this.isNorthAmericanTrack &&
            this.entry && this.entry.JockeyId > 0) ||
            (this.viewableWagerData && this.viewableWagerData.driverStats);
    }

    public hasTrainerDetails(): boolean {
        return (this.isTbredTrack &&
            this.isNorthAmericanTrack &&
            this.entry && this.entry.TrainerId > 0) ||
            (this.viewableWagerData && this.viewableWagerData.trainerStats);
    }

    public hasSireDetails(): boolean {
        return this.isTbredTrack &&
            this.isNorthAmericanTrack &&
            this.entry && this.entry.SireName && this.entry.SireId > 0
    }

    public hasDamDetails(): boolean {
        return this.isTbredTrack &&
            this.isNorthAmericanTrack &&
            this.entry && this.entry.DamName && this.entry.DamId > 0
    }

    public hasJockeyName(): boolean {
        return this.jockeyName !== '-';
    }

    public canShowOddsTrend(): boolean {
        return this.isOddsTrendEnabled && this.section === ViewSectionEnum.PROGRAM_ADVANCED && this.oddsTrendArray.length > 0;
    }

    /**
     * toggle odds details row
     */
    public oddsDetails(): void {
        if (this.isShowingOddsTrend = this.canShowOddsTrend() && !this.isShowingOddsTrend) {
            this._eventTrackingService.logClickEvent(EventClickType.ODDS_TREND_EXPAND);
        }
    }

    /**
     * redirect to runner details route
     */
     public runnerDetails(): void {
        if (!this.hasRunnerDetails()) { return; }

        if (this._showDetailsInline()) {
            this._toggleDetailsContainer(this.isGreyTrack ? enumDetailsType.RUNNER_GH : enumDetailsType.RUNNER);
            this._eventTrackingService.logClickEvent(EventClickType.PROGRAM_RUNNER_DETAILS)
        } else {
            this.addCurrentViewBreadcrumb();

            this._router.navigateByUrl([
                '/program',
                this.view,
                this.isGreyTrack ? 'greyhound' : (this.isHarnessTrack ? 'harness' : 'horse'),
                this._stringSlugify.transform(this.entry.Name),
                this.track.BrisCode,
                this.track.TrackType,
                this.track.RaceNum + this._activeLeg,
                !this.isTbredTrack ? this.entry.ProgramNumber : this.entry.EntryId
            ].join('/'));
        }
    }

    /**
     * redirect to jockey details route
     */
     public jockeyDetails(): void {
        if (!this.hasJockeyDetails()) { return; }

        if (this._showDetailsInline()) {
            this._eventTrackingService.logClickEvent(EventClickType.PROGRAM_JOCKEY_DETAILS)
            this._toggleDetailsContainer(
                this.track.TrackType === enumTrackType.HARN
                    ? enumDetailsType.DRIVER
                    : enumDetailsType.JOCKEY
            );
        } else {
            this.addCurrentViewBreadcrumb();
            let jockeyDetailsRoute: string;
            switch (this.track.TrackType) {
                case enumTrackType.HARN:
                    jockeyDetailsRoute = [
                        '/program',
                        this.view,
                        'harness',
                        this._stringSlugify.transform(this.trainerName),
                        this.track.BrisCode,
                        this.track.TrackType,
                        this.track.RaceNum + this._activeLeg,
                        this.entry.ProgramNumber,
                        'driver',
                    ].join('/');
                    break;
                default:
                    jockeyDetailsRoute = [
                        '/program',
                        this.view,
                        'jockey',
                        this._stringSlugify.transform(this.jockeyName),
                        this.track.BrisCode,
                        this.track.TrackType,
                        this.track.RaceNum + this._activeLeg,
                        this.entry.JockeyId,
                    ].join('/');
                    if (this.entry.TrainerId > 0) {
                        jockeyDetailsRoute += '/' + this.entry.TrainerId;
                    }
            }
            if (this.entry.JockeyChange && this.track.TrackType === enumTrackType.TBRED) {
                jockeyDetailsRoute += '/change';
            }

            this._router.navigateByUrl(jockeyDetailsRoute);
        }
    }

    /**
     * redirect to trainer details route
     */
     public trainerDetails(): void {
        if (!this.hasTrainerDetails()) { return; }

        if (this._showDetailsInline()) {
            this._toggleDetailsContainer(
                this.track.TrackType === enumTrackType.HARN
                    ? enumDetailsType.TRAINER_HARN
                    : enumDetailsType.TRAINER
            );
            this._eventTrackingService.logClickEvent(EventClickType.PROGRAM_TRAINER_DETAILS)
        } else {
            this.addCurrentViewBreadcrumb();

            let trainerDetailsRoute: string;
            switch (this.track.TrackType) {
                case enumTrackType.HARN:
                    trainerDetailsRoute = [
                        '/program',
                        this.view,
                        'harness',
                        this._stringSlugify.transform(this.trainerName),
                        this.track.BrisCode,
                        this.track.TrackType,
                        this.track.RaceNum + this._activeLeg,
                        this.entry.ProgramNumber,
                        'trainer'
                    ].join('/');
                    break;
                default:
                    trainerDetailsRoute = [
                        '/program',
                        this.view,
                        'trainer',
                        this._stringSlugify.transform(this.jockeyName),
                        this.track.BrisCode,
                        this.track.TrackType,
                        this.track.RaceNum + this._activeLeg,
                        this.entry.TrainerId,
                    ].join('/');
                    if (this.entry.JockeyId > 0) {
                        trainerDetailsRoute += '/' + this.entry.JockeyId;
                    }
            }

            this._router.navigateByUrl(trainerDetailsRoute);
        }
    }

    /**
     * redirect to sire details route
     */
     public sireDetails(): void {
        if (!this.hasSireDetails()) { return; }

        if (this._showDetailsInline()) {
            this._toggleDetailsContainer(enumDetailsType.SIRE);
            this._eventTrackingService.logClickEvent(EventClickType.PROGRAM_SIRE_DETAILS)
        } else {
            this.addCurrentViewBreadcrumb();
            this._router.navigateByUrl([
                '/program',
                this.view,
                'sire',
                this._stringSlugify.transform(this.entry.SireName),
                'id',
                this.entry.SireId
            ].join('/'));
        }
    }

    /**
     * redirect to dam details route
     */
     public damDetails(): void {
        if (!this.hasDamDetails()) { return; }

        if (this._showDetailsInline()) {
            this._toggleDetailsContainer(enumDetailsType.DAM);
            this._eventTrackingService.logClickEvent(EventClickType.PROGRAM_DAM_DETAILS)
        } else {
            this.addCurrentViewBreadcrumb();
            this._router.navigateByUrl([
                '/program',
                this.view,
                'dam',
                this._stringSlugify.transform(this.entry.DamName),
                'id',
                this.entry.DamId
            ].join('/'));
        }
    }

    /**
     * generate a bread crumb to get back to classic program component from respective redirected routes
     */
    private addCurrentViewBreadcrumb() {
        let url = this._location.path(false);
        if (url.indexOf('?') > 0) {
            url = url.slice(0, url.indexOf('?'));
        }

        this._breadcrumbsService.addBreadcrumb(
            BreadcrumbsService.generateBreadcrumb(this.track.DisplayName + ' - Race ' + this.track.RaceNum, url),
            BreadcrumbsService.TRUNCATE_BREADCRUMBS
        );
    }

    private joinNames(...strings: string[]) {
        return strings.join(' ').replace(/\s+/g, ' ');
    }

    public areOddsTrending(oddsTrendDetail: IOddsTrendDetails): number {
        if (oddsTrendDetail && oddsTrendDetail.change) {
            return oddsTrendDetail.change < 0 ? -1 : 1;
        }

        return 0;
    }

    private setSummaryDetails() {
        const options: IDetailsOptions = {
            brisCode: this.track.BrisCode,
            trackType: this.track.TrackType,
            raceNumber: this.track.RaceNum,
            activeLeg: this._activeLeg,
            programNumber: this.entry.ProgramNumber,
            view: this.view,
            horseId: this.entry.EntryId,
            trackName: this.track.DisplayName,
            trainerId: this.entry.TrainerId,
            trainerName: this.entry.TrainerName,
            jockeyId: this.entry.JockeyId,
            jockeyName: this.entry.JockeyName,
            sireId: this.entry.SireId,
            sireName: this.entry.SireName,
            damId: this.entry.DamId,
            damName: this.entry.DamName,
            oddsTrendArray: this.entry.OddsTrend
        };
        this.detailsComponent.init(options);
    }


    private _toggleDetailsContainer(detailsType: enumDetailsType) {
        if (this.isEntrySummaryEnabled) {
            if (!detailsType || this.showingDetailsType === detailsType) {
                if (this._detailsContainer) {
                    this._detailsContainer.clear();
                }
                this.showingDetailsType = null;
            } else {
                if (this._detailsContainer) {
                    this._detailsContainer.clear();
                }

                this.detailsComponent = this._detailsContainer.createComponent(this._componentFactoryResolver.resolveComponentFactory(SummaryDetailsComponent)).instance as IDetails;

                this.setSummaryDetails();
                this.showingDetailsType = detailsType;
            }
        } else {
            if (!detailsType || this.showingDetailsType === detailsType) {
                if (this._detailsContainer) {
                    this._detailsContainer.clear();
                }
                this.showingDetailsType = null;
            } else {
                if (this._detailsContainer) {
                    this._detailsContainer.clear();
                }

                const detailsComponent = this._detailsContainer.createComponent(this._getComponentFactoryFromDetailsType(detailsType)).instance as IDetails;

                const options: IDetailsOptions = {
                    brisCode: this.track.BrisCode,
                    trackType: this.track.TrackType,
                    raceNumber: this.track.RaceNum,
                    activeLeg: this._activeLeg,
                    programNumber: this.entry.ProgramNumber,
                    view: this.view,
                    horseId: this.entry.EntryId,
                    trackName: this.track.DisplayName,
                    trainerId: this.entry.TrainerId,
                    trainerName: detailsType === enumDetailsType.TRAINER ? this.entry.TrainerName : null,
                    jockeyId: this.entry.JockeyId,
                    jockeyName: detailsType === enumDetailsType.JOCKEY ? this.entry.JockeyName : null,
                    jockeyChange: this.entry.JockeyChange,
                    sireId: detailsType === enumDetailsType.SIRE ? this.entry.SireId : null,
                    sireName: detailsType === enumDetailsType.SIRE ? this.entry.SireName : null,
                    damId: detailsType === enumDetailsType.DAM ? this.entry.DamId : null,
                    damName: detailsType === enumDetailsType.DAM ? this.entry.DamName : null,
                    oddsTrendArray: this.entry.OddsTrend
                };
                detailsComponent.init(options, this.entry);
                this.showingDetailsType = detailsType;
            }
        }
    }

    private _getComponentFactoryFromDetailsType(detailsType: enumDetailsType): ComponentFactory<IDetails> {
        let component;
        switch (detailsType) {
            case enumDetailsType.DRIVER:
                component = DriverDetailsComponent;
                break;
            case enumDetailsType.RUNNER:
                component = RunnerDetailsComponent;
                break;
            case enumDetailsType.RUNNER_GH:
                component = GreyhoundDetailsComponent;
                break;
            case enumDetailsType.TRAINER_HARN:
                component = TrainerDetailsComponent;
                break;
            case enumDetailsType.TRAINER:
            case enumDetailsType.JOCKEY:
                component = TrainerJockeyDetailsComponent;
                break;
            case enumDetailsType.SIRE:
                component = SireDetailsComponent;
                break;
            case enumDetailsType.DAM:
                component = DamDetailsComponent;
                break;
        }
        return this._componentFactoryResolver.resolveComponentFactory(component);
    }

    private _showDetailsInline(): boolean {
        return this.view === WageringViewEnum.CLASSIC || this.isEntrySummaryEnabled;
    }
}

