import { Component, OnInit, TemplateRef, ViewChild, Inject, AfterViewInit, ElementRef } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CduxRouteUtil } from 'app/shared/common';
import * as braze from '@braze/web-sdk';
import { InboxService } from 'app/shared/menu-items/services/inbox.service';

interface ProcessedCard {
    card: any;
    template: TemplateRef<any> | null;
}

@Component({
    selector: 'cdux-inbox-feed',
    templateUrl: './inbox-feed.component.html',
    styleUrls: ['./inbox-feed.component.scss']
})
export class InboxFeedComponent implements OnInit, AfterViewInit {
    @ViewChild('inboxFeedContainer') inboxFeedContainer: ElementRef;
    scrollContainer: HTMLElement;

    @ViewChild('imageOnlyCardTemplate', {static: true}) imageOnlyCardTemplateRef!: TemplateRef<any>;
    @ViewChild('classicCardTemplate', {static: true}) classicCardTemplateRef!: TemplateRef<any>;
    @ViewChild('captionedImageCardTemplate', {static: true}) captionedImageCardTemplateRef!: TemplateRef<any>;

    private cardsSubject = new BehaviorSubject<ProcessedCard[]>([]);
    cards$: Observable<ProcessedCard[]> = this.cardsSubject.asObservable();
    // private destroy$ = new Subject<void>();

    public i: number;
    public offersLink: string;

    constructor(
        private _elementRef: ElementRef,
        @Inject(InboxService) private _inboxService: InboxService
    ) {}

    ngOnInit(): void {
        this._subscribeToInboxData();
        this.offersLink = CduxRouteUtil.getBaseHref(true) + 'offers';
    }

    ngAfterViewInit(): void {
        const observer = new IntersectionObserver(
            () => {},
            { threshold: 0.5 }
        );
        observer.observe(this._elementRef.nativeElement);
    }

    // ngOnDestroy(): void {
    //     this.destroy$.next();
    //     this.destroy$.complete();
    // }

    private _subscribeToInboxData(): void {
        this.cards$ = this._inboxService.getInboxData().pipe(
            // takeUntil(this.destroy$),
            map((contentCards: braze.ContentCards | null) => {
                if (contentCards && Array.isArray(contentCards.cards)) {
                    return contentCards.cards;
                } else {
                    console.warn('No valid cards received from InboxService');
                    return [];
                }
            }),
            map(this._processCards.bind(this)),
            map(this._sortCards),
            catchError((error: any) => {
                console.error('Error processing content cards:', error);
                return of([]);
            })
        );
    }


    private _processCards(cards: braze.Card[]): ProcessedCard[] {
        if (!Array.isArray(cards)) {
            console.warn('Received non-array cards:', cards);
            return [];
        }
        return cards
            .filter(cd => cd && !cd.extras?.isDismissed)
            .map(card => ({
                card: card,
                template: this.handleCardView(card)
        }))
        .filter(processedCard => processedCard.template !== null);
    }

    private _sortCards(processedCards: ProcessedCard[]): ProcessedCard[] {
        return processedCards.sort((a, b) => {
            if (a.card.pinned && !b.card.pinned) return -1;
            if (!a.card.pinned && b.card.pinned) return 1;
            return new Date(b.card.updated).getTime() - new Date(a.card.updated).getTime();
        });
    }

    public trackByCardId(index: number, processedCard: ProcessedCard): string {
        return processedCard.card?.id || index.toString();
    }

    public handleCardView(card: any): TemplateRef<any> | null {
        if (card instanceof braze.ImageOnly) {
            return this.imageOnlyCardTemplateRef;
        }
        if (card instanceof braze.CaptionedImage) {
            return this.captionedImageCardTemplateRef;
        }
        if (card instanceof braze.ClassicCard) {
            return this.classicCardTemplateRef;
        }
        return null;
    }

    public deleteAllContentCards(): void {
        this._inboxService.deleteAllContentCards()
            .pipe(
                // takeUntil(this.destroy$),
                catchError(error => {
                    console.error('Error deleting all content cards:', error);
                    return [];
                })
            )
            .subscribe(
                error => {
                    if (error !== undefined)
                        console.error('Error in deleteAllContentCards:', error);
                }
            );
    }

    public onVisibilityChange(isVisible: boolean, card: any) {
        if (isVisible) {
            this._inboxService.logCardImpression(card);
        }
    }

}
