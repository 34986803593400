import { Component, OnInit, OnDestroy } from '@angular/core';
import { LocationStrategy, Location } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { BehaviorSubject, combineLatest, concat, Observable, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, share } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';


import { ColorSchemeService, enumColorScheme, ModalService } from '@cdux/ng-platform/web';
import {
    EventsService,
    FeatureToggleDataService,
    JwtSessionService,
    PreferencesService,
    WindowManagerService,
    UserEventEnum,
} from '@cdux/ng-common';
import { DropdownService } from '@cdux/ng-fragments';

import { EventTrackingService } from './shared/event-tracking/services/event-tracking.service';
import { CduxRouteUtil, DomUtil } from './shared/common/utils';
import { FEATURE_TOGGLE_RESPONSIVE } from './shared/common/constants';
import {
    InterceptorService,
    DeeplinkInterceptor
} from './shared/interceptor';
import { EventKeys } from './shared/common/events';
import { SidebarComponent } from './sidebar-component';
import { PrimaryViewComponent } from './primary-view.component';
import { DefaultSidebarComponent } from './shared/program/components/default-sidebar/default-sidebar.component';
import { PreferencesComponent } from './shared/my-account/preferences/preferences.component';
import { ToastInterceptor } from './shared/interceptor/interceptors/toast.interceptor';
import { ActivityService } from './shared/notification/services/activity.service';
import { InboxService } from './shared/menu-items/services/inbox.service';

@Component({
    selector: 'cdux-tux',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

    public currentSidebar: ComponentPortal<SidebarComponent>;

    private _systemThemeSubject = new BehaviorSubject<enumColorScheme>(enumColorScheme.LIGHT);
    private _navigationEndObservable = <Observable<NavigationEnd>> this._router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        share()
    );

    private _sessionPulseCheckSubscription: Subscription;
    private _responsiveTuxFeatureToggleSubscription: Subscription;
    private _activityMonitorSubscription: Subscription;

    // DE9115: This list of routes are meant to be full page routes.
    // On some devices (Safari iOS) that don't respect z-indexing on child elements,
    // we can use this list to hide the element to allow appropriate display
    // of full screen routes.
    private _fullpageRoutes = [
        '/login',
        '/password/forgot',
        '/password/reset',
        '/tournaments-selection',
        '/video'
    ];

    constructor(
        private _deeplinkInterceptor: DeeplinkInterceptor,
        public dropdownService: DropdownService,
        public _eventTrackingService: EventTrackingService,
        private _eventsService: EventsService,
        private _interceptorService: InterceptorService,
        private _locationStrategy: LocationStrategy,
        private _modalService: ModalService,
        private _preferencesService: PreferencesService,
        private _colorSchemeService: ColorSchemeService,
        private _toastInterceptor: ToastInterceptor,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _sessionService: JwtSessionService,
        private _windowManager: WindowManagerService,
        private _featureToggleService: FeatureToggleDataService,
        private _activityService: ActivityService,
        private _title: Title,
        private _location: Location,
        inboxService: InboxService
    ) {
        // Empty
    }

    public ngOnInit() {
        this._sessionPulseCheckSubscription = this._sessionService.pulseCheck().subscribe();
        this.bindWindowManagerUnload();
        // On route changes, we scroll cdux-body back to the top.
        // This ensures on route changes, the user is always placed at the top of the page.
        // This works with standard browser navigation as well.
        // this._router.events.subscribe(() => {
        //     document.getElementById('cdux-body').scrollTop = 0;
        // });

        // close all modals on popstate (back/forward)
        this._locationStrategy.onPopState((state) => {
            this._modalService.closeAll();
        });

        this._navigationEndObservable.subscribe(
            (e: NavigationEnd) => {
                this._eventTrackingService.logGoogleTagManagerEvent({
                    pageName: this._title.getTitle(),
                    pageType: !!this._location.path().split('/')[1] ? this._location.path().split('/')[1] : ''
                })
                this._eventTrackingService.logGtmUserEvent(UserEventEnum.PAGEVIEW, { 'url': e.url , referrer: document.referrer});
            }
        );

        this._interceptorService.injectRouter(this._router);
        this._deeplinkInterceptor.register();
        this._toastInterceptor.register();
        this.clearSavedStates();

        /**
         * This block of functionality is in place to prevent TUX users from
         * seeing the Responsive designs until they are completed at which point
         * this will be removed.
         */
        // Check the Feature Toggle for Responsive TUX
        this._responsiveTuxFeatureToggleSubscription = this._featureToggleService.watchFeatureToggle(FEATURE_TOGGLE_RESPONSIVE).subscribe((status) => {
            // If Responsive Tux is Disabled
            if (!status) {
                DomUtil.setViewport(1024, 1.0, true); // Set a Minimum Viewport Width
            } else {
                DomUtil.setViewport('device-width', 1.0, true); // Allow the Viewport Width to scale freely
            }
        });

        this._activityService.startActivityTimer().subscribe();

        if (this._featureToggleService.isFeatureToggleOn('DARK_MODE')) {
            // listen for changes to determine theme
            combineLatest([
                concat(
                    of(this._sessionService.isLoggedIn()),
                    this._sessionService.onAuthenticationChange
                ).pipe(
                    distinctUntilChanged()
                ),
                this._navigationEndObservable.pipe(
                    map(() =>
                        !!CduxRouteUtil.getCurrentPathConfig(this._activatedRoute.snapshot)?.disableThemes
                    ),
                    distinctUntilChanged()
                ),
                this._systemThemeSubject.pipe(
                    distinctUntilChanged()
                ),
                this._preferencesService.watchFlag(PreferencesComponent.USE_SYSTEM_THEME_KEY, false),
                this._preferencesService.watchFlag(PreferencesComponent.FORCE_DARK_MODE_KEY, false)
            ]).pipe(
                debounceTime(100),
                map(([isLoggedIn, isThemeDisabled, systemTheme, useSystemTheme, forceDarkMode]) =>
                    !isLoggedIn || isThemeDisabled ? enumColorScheme.LIGHT : useSystemTheme ? systemTheme
                        : forceDarkMode ? enumColorScheme.DARK : enumColorScheme.LIGHT // default to LIGHT
                ),
                distinctUntilChanged()
            ).subscribe((theme) =>
                this._colorSchemeService.setColorScheme(theme)
            );

            // listen for system level changes to theme
            const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
            this._systemThemeSubject.next(mediaQuery.matches ? enumColorScheme.DARK : enumColorScheme.LIGHT);
            mediaQuery.addEventListener('change', (q) => this._systemThemeSubject.next(q.matches ? enumColorScheme.DARK : enumColorScheme.LIGHT));
        }
    }

    public ngOnDestroy() {
        this._sessionPulseCheckSubscription.unsubscribe();
        this._responsiveTuxFeatureToggleSubscription.unsubscribe();
        this._activityMonitorSubscription.unsubscribe();
    }

    /**
     * @deprecated
     */
    public scrolling(event) {
        this._eventsService.broadcast(EventKeys.MAIN_SCROLLING, event);
    }

    private bindWindowManagerUnload() {
        window.addEventListener('unload', this._windowManager.onUnload());
    }

    private clearSavedStates() {
        // something something dark side.
        const matches: string[] = [];
        for (let i = 0; i < sessionStorage.length; i++) {
            const key = sessionStorage.key(i);
            if (/Sort$|View$/.test(key)) {
                matches.push(key);
            }
        }

        matches.forEach(key => {
            sessionStorage.removeItem(key);
        });
    }

    /**
     * @deprecated
     */
    public checkIfShown(): boolean {
        // return true if the current page is not in the fullscreenRoutes list
        let matchFlag = true;
        this._fullpageRoutes.forEach((route: string) => {
            if (this._router.url.includes(route)) {
                matchFlag = false;
            }
        });

        return matchFlag;
    }

    /**
     * @deprecated
     */
    public onRouterOutletActivate(component: Component) {
        let newSideBar: ComponentType<SidebarComponent> = null;

        // If no sidebar has been loaded at all,
        // then we'll be sure to at least load the main sidebar.
        if (!this.currentSidebar) {
            newSideBar = DefaultSidebarComponent;
        }

        // If we are loading a PrimaryViewComponent (which have sidebars),
        // then we'll get it's sidebar
        if (component instanceof PrimaryViewComponent) {
            newSideBar = (<PrimaryViewComponent>component).getSidebarComponent();
        }

        // if there is no sidebar loaded,
        // or if newSidebar is set and is different than the current sidebar,
        // then we'll load the new sidebar.
        if (!this.currentSidebar || (newSideBar && newSideBar !== this.currentSidebar.component)) {

            this.currentSidebar = new ComponentPortal(newSideBar);
        }
    }
}
