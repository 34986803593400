<div class="compact-view">
    <!--  Needs swapped with the new section stuff  -->
    <cdux-wagering-section-container
        id="{{ view + '_' + section }}"
        [view]="view"
        [displayMode]="displayMode"
        [section]="section"
        [wagerState]="wager"
        [raceStatus]="raceStatus"
        [displayMode]="displayMode"
        (sectionChange)="sectionChange.emit($event)"
        (updateRaceNav)="updateRaceNav.emit($event)"
        (updateBetNav)="updateBetNav.emit($event)"
        (updateBetType)="onUpdateBetType($event)"
        (updateBetAmount)="onUpdateBetAmount($event)"
        (updateBetModifier)="onUpdateBetModifier($event)"
        (onSelectionChange)="onUpdateEntrySelections($event)"
        (onAlternateSelectionChange)="onAlternateSelection($event)"
        (onResetEntrySelections)="resetEntrySelections.emit()">
    </cdux-wagering-section-container>

    <ng-container *ngIf="isWagerableRace && displayMode === DisplayModeEnum.COMPACT">
            <cdux-quick-pick-sidebar *ngIf="isQuickPickSidebarEnabled && isWagerableRace && !displayTotalBar"
                [class.disabled]="!quickPickSidebar?.isWagerableRace || !quickPickSidebar?.isPoolAvailable"
                (outboundEvents)="outboundEvents.emit($event)"
                [displayMode]="displayMode"
                [raceStatus]="raceStatus"
                [wagerState]="wager"
                (opened)="stashEntrySelections.emit()"
                (closed)="restoreEntrySelections.emit()">
            </cdux-quick-pick-sidebar>

            <section class="error-row" [style.display]="displayTotalBar && error ? 'flex' : 'none'">
                <cdux-quickbet-error class="compact"
                                    [error]="error"
                                    (errorClose)="dismissError.emit($event)">
                </cdux-quickbet-error>
            </section>
            <section class="total-bar-row" [style.display]="displayTotalBar && !error ? 'block' : 'none'">
                <cdux-compact-total-bar [wagerState]="wager"
                                        [inboundEvents]="inboundEvents"
                                        [raceStatus]="raceStatus"
                                        (resetEntrySelections)="resetEntrySelections.emit()"
                                        (outboundEvents)="handleEvent($event)">
                </cdux-compact-total-bar>
            </section>
    </ng-container>
</div>
