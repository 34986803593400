import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { take, tap, share } from 'rxjs/operators';
import * as braze from '@braze/web-sdk';
import { BrazeUtils } from '@cdux/ts-domain-braze';

@Injectable({
    providedIn: 'root'
})
export class InboxService {
    private _isOpen = new BehaviorSubject<boolean>(false);
    private _hasNotifications = new BehaviorSubject<boolean>(false);
    private _inboxData$ = new BehaviorSubject<braze.ContentCards | null>(null);

    constructor(private brazeUtils: BrazeUtils) { }

    public initializeInbox(): void {
        const contentCards$ = this.brazeUtils.getContentCards().pipe(
            share(),
            tap((cards: braze.ContentCards) => {
                this._inboxData$.next(cards);
                this._updateNotifications(cards);
            })
        );

        // React to every update
        contentCards$.subscribe();
        this.refreshContentCards();
    }

    public getInboxData(): Observable<braze.ContentCards | null> {
        return this._inboxData$.asObservable();
    }

    public  refreshContentCards(): void {
            this.brazeUtils.refreshContentCards()
            .then(() => {
                this._updateNotifications(null);
            })
            .catch((error) => {
                console.error('Error refreshing content cards:', error);
                this.getCachedCards();
                this._updateNotifications(null);
                throw new Error('Failed to refresh content cards');
        });
    }

    public getCachedCards(): void {
        this.brazeUtils.getCachedCards();
    }

    private _updateNotifications(cards: braze.ContentCards | null): void {
        if (cards === null) {
          // If cards is null, fetch the latest data
          this.brazeUtils.getContentCards().pipe(take(1)).subscribe(
            latestCards => {
                const hasNotifications = latestCards ? latestCards.getUnviewedCardCount() > 0 : false;
                this._hasNotifications.next(hasNotifications);
            }
          );
        } else {
            const hasNotifications = cards.getUnviewedCardCount() > 0;
            this._hasNotifications.next(hasNotifications);
        }
    }

    public isInboxOpen(): Observable<boolean> {
        return this._isOpen.asObservable();
    }

    public openInbox(): void {
        this._isOpen.next(true);
        this.refreshContentCards();
    }

    public closeInbox(): void {
        this._isOpen.next(false);
    }

    public get hasNotifications$(): Observable<boolean> {
        return this._hasNotifications.asObservable();
    }

    public logCardImpression(card: braze.Card): void {
        this.brazeUtils.logCardImpression([card]);
        this._updateNotifications(null);
    }

    public deleteAllContentCards(): Observable<void> {
        return new Observable(observer => {
            const currentCards = this._inboxData$.getValue();
            if (currentCards && currentCards.cards) {
                const pinnedCards = currentCards.cards.filter(card => card.pinned);
                const cardsToDelete = currentCards.cards.filter(card => !card.pinned);

                // Perform actual deletion
                cardsToDelete.forEach(card => this.brazeUtils.dismissCard(card));

                // Create a new ContentCards object with only pinned cards
                const updatedContentCards = new braze.ContentCards(pinnedCards, currentCards.lastUpdated);

                // Emit the updated ContentCards object
                this._inboxData$.next(updatedContentCards);

                this._updateNotifications(updatedContentCards);
                observer.next();
                observer.complete();
            } else {
                observer.next(); // No cards to delete, but not necessarily an error
                observer.complete();
            }
        });
    }
}
