<ng-template #offloadedTemplate>
    <nav class="content-nav" [class.has-offloaded-content]="displayMode === DisplayModeEnum.LARGE"
        [class.compact]="displayMode === DisplayModeEnum.COMPACT || displayMode === DisplayModeEnum.MOBILE">
        <ul>
            <li *ngIf="isClassicEnabled && displayMode === DisplayModeEnum.LARGE" id="wps-linear" [class.is-selected]="section === ViewSectionEnum.RUNNER_BALLOT" (click)="section !== ViewSectionEnum.RUNNER_BALLOT && viewLinear()">
                Classic</li>
            <li id="wps-basic" [class.is-selected]="section === ViewSectionEnum.PROGRAM_BASIC" (click)="section !== ViewSectionEnum.PROGRAM_BASIC && viewBasic()">
                Basic</li>
            <li id="wps-advanced" [class.is-selected]="section === ViewSectionEnum.PROGRAM_ADVANCED"
                (click)="section !== ViewSectionEnum.PROGRAM_ADVANCED && viewAdvanced()">Advanced</li>
            <li id="wps-speed" *ngIf="isSpeedEnabled && viewableData?.speed" [class.is-selected]="section === ViewSectionEnum.PROGRAM_SPEED"
                (click)="section !== ViewSectionEnum.PROGRAM_SPEED && viewSpeed()">Speed</li>
            <li id="wps-class" *ngIf="isClassEnabled && viewableData?.class" [class.is-selected]="section === ViewSectionEnum.PROGRAM_CLASS"
                (click)="section !== ViewSectionEnum.PROGRAM_CLASS && viewClass()">Class</li>
            <li id="wps-pace" *ngIf="isPaceEnabled && viewableData?.pace" [class.is-selected]="section === ViewSectionEnum.PROGRAM_PACE"
                (click)="section !== ViewSectionEnum.PROGRAM_PACE && viewPace()">Pace</li>
            <li id="wps-summary" *ngIf="isSummaryEnabled && isTBNA" [class.is-selected]="section === ViewSectionEnum.PROGRAM_SUMMARY"
                (click)="section !== ViewSectionEnum.PROGRAM_SUMMARY && viewSummary() || handleSummaryAuth()">Summary</li>
            <li id="wps-tips" *ngIf="isTBNA"
                [class.is-selected]="section === ViewSectionEnum.PROGRAM_TIPS" (click)="section !== ViewSectionEnum.PROGRAM_TIPS && viewAngles()">Tips</li>
        </ul>
        <div *ngIf="displayMode === DisplayModeEnum.LARGE" class="offloaded-content" [class.compact]="displayMode === DisplayModeEnum.COMPACT || displayMode === DisplayModeEnum.MOBILE">
            <div id="scratches-changes" class="button smallest" (click)="viewScratches()" [class.is-active]="showScratches">Scratches & Changes</div>
        </div>
    </nav>
</ng-template>

<div class="message-banner" *ngIf="raceStatus && !isWagerableRace() && section !== ViewSectionEnum.RUNNER_BALLOT" (click)="redirectToResult()" [ngClass]="{'cursor-pointer': isRaceClosed}">
    <div class="gbl-message">
        <div class="ts-message"><i class="icon&#45;&#45;exclamation-alert"></i> Betting is closed for this race.
            <span *ngIf="isRaceClosed" class="text-underline"> View Results.</span>
        </div>
    </div>
</div>
<div class="program-container" [style.display]="showScratches && displayMode === DisplayModeEnum.LARGE ? 'none' : ''">
    <div [class.classic-bet-bar]="view === WageringViewEnum.CLASSIC"
        *ngIf="(view === WageringViewEnum.CLASSIC || WageringViewEnum.VIDEO) && isWagerableRace() && section !== ViewSectionEnum.RUNNER_BALLOT">
        <div cduxVisible
            (isHidden)="pinBetNav = !!$event"
            [visibilityTopMargin]="70"
            [class.classic-bet-bar-item]="view === WageringViewEnum.CLASSIC"
            [class.bet-nav-row]="displayMode === DisplayModeEnum.COMPACT || displayMode === DisplayModeEnum.MOBILE">
            <cdux-common-bet-nav [class.pinned]="pinBetNav && displayMode === DisplayModeEnum.MOBILE" [selectedRaceNav]="wagerState?.basicTrack" [selectedBetNav]="wagerState?.betNav"
                (availableBetTypes)="onBetNavChange($event)" (updateBetType)="onUpdateBetType($event)"
                (updateBetAmount)="onUpdateBetAmount($event)" (updateBetModifier)="onUpdateBetModifier($event)">
            </cdux-common-bet-nav>
        </div>
        <div class="classic-bet-bar-item" *ngIf="displayMode !== DisplayModeEnum.COMPACT && displayMode !== DisplayModeEnum.MOBILE">
            <cdux-runner-bar class="full-size" [wagerState]="wagerState" [raceStatus]="raceStatus"
                (onClear)="onResetEntrySelections.emit($event)">
            </cdux-runner-bar>
        </div>
    </div>
    <cdux-carryover-banner *ngIf="isCarryoversEnabled && section !== ViewSectionEnum.RUNNER_BALLOT" [track]="wagerState?.basicTrack" (updateRaceNav)="updateRaceNav.emit($event)" (updateBetType)="onUpdateBetType($event)"></cdux-carryover-banner>
    <div class="classic-bet-bar tooltip-bar" [class.angles-pick-bar]="section === ViewSectionEnum.PROGRAM_TIPS" [class.compact]="displayMode === DisplayModeEnum.COMPACT || displayMode === DisplayModeEnum.MOBILE" [class.gray-background-on-dark-mode]="showCompactBrisPicks">
        <div class="classic-bet-bar-item" *ngIf="displayMode === DisplayModeEnum.LARGE && (section === ViewSectionEnum.PROGRAM_ADVANCED || section === ViewSectionEnum.PROGRAM_BASIC)">

            <div class="what-is program-glossary-tooltip hover click">
                <span>
                    Glossary
                    <div class="icon--info"></div>
                </span>
                <div class="program-glossary-tooltip-info tooltip-content">
                    <h2>HANDICAPPING INFO</h2>
                    <div class="glossary-columns">
                        <div>
                            <h3>{{ 'odds-title' | cduxTranslate:'program-tooltips' | uppercase }}</h3>
                            <ul>
                                <li>{{ 'odds-detail' | cduxTranslate:'program-tooltips' }}</li>
                            </ul>

                            <ng-container *ngIf="section === ViewSectionEnum.PROGRAM_ADVANCED">
                                <h3>{{ 'odds-trend-title' | cduxTranslate:'program-tooltips' | uppercase }}</h3>
                                <ul>
                                    <li>{{ 'odds-trend-detail' | cduxTranslate:'program-tooltips' }}</li>
                                </ul>
                            </ng-container>

                            <h3>{{ (section === ViewSectionEnum.PROGRAM_ADVANCED ? 'ml-adv-title' : 'ml-basic-title') | cduxTranslate:'program-tooltips' | uppercase }}</h3>
                            <ul>
                                <li>{{ 'ml-detail' | cduxTranslate:'program-tooltips' }}</li>
                            </ul>

                            <ng-container *ngIf="isProfitLineEnabled && trackType === enumTrackType.TBRED">
                                <h3>{{ 'pl-title' | cduxTranslate:'program-tooltips' | uppercase }}</h3>
                                <ul>
                                    <li>{{ 'pl-detail' | cduxTranslate:'program-tooltips' }}</li>
                                </ul>
                            </ng-container>

                            <h3>{{ 'runner-info-title' | cduxTranslate:'program-tooltips' | uppercase }}</h3>
                            <ul [ngSwitch]="trackType">
                                <li *ngSwitchCase="enumTrackType.TBRED">
                                    {{ (section === ViewSectionEnum.PROGRAM_ADVANCED ? 'runner-info-adv-detail' : 'runner-info-basic-detail') | cduxTranslate:'program-tooltips' }}
                                </li>
                                <li *ngSwitchCase="enumTrackType.HARN">
                                    {{ (section === ViewSectionEnum.PROGRAM_ADVANCED ? 'runner-info-adv-detail' : 'runner-info-basic-detail') | cduxTranslate:'program-tooltips' }}
                                </li>
                                <li *ngSwitchCase="enumTrackType.GREY">
                                    {{ (section === ViewSectionEnum.PROGRAM_ADVANCED ? 'runner-info-grey-adv-detail' : 'runner-info-basic-detail') | cduxTranslate:'program-tooltips' }}
                                </li>
                            </ul>
                        </div>
                        <div>
                            <ng-container *ngIf="section === ViewSectionEnum.PROGRAM_BASIC">
                                <h3>{{ (trackType === enumTrackType.GREY ? 'med-weight-equip-grey-title' : 'med-weight-equip-title') | cduxTranslate:'program-tooltips' | uppercase }}</h3>
                                <ul>
                                    <li>{{ (trackType === enumTrackType.GREY ? 'med-weight-equip-grey-detail' : 'med-weight-equip-detail') | cduxTranslate:'program-tooltips' }}</li>
                                </ul>
                            </ng-container>

                            <ng-container *ngIf="trackType === enumTrackType.TBRED">
                                <h3>{{ 'jockey-info-title' | cduxTranslate:'program-tooltips' | uppercase }}</h3>
                                <ul>
                                    <li *ngIf="section === ViewSectionEnum.PROGRAM_ADVANCED">
                                        {{ 'jockey-info-adv-detail' | cduxTranslate:'program-tooltips' }}
                                        <ng-container *ngIf="trackType === enumTrackType.TBRED">
                                            <br>
                                            {{ 'jockey-stats-title' | cduxTranslate:'program-tooltips' }} -
                                            {{ 'jockey-stats-detail' | cduxTranslate:'program-tooltips' }}
                                        </ng-container>
                                    </li>
                                    <li *ngIf="section === ViewSectionEnum.PROGRAM_BASIC">
                                        {{ 'jockey-info-basic-detail' | cduxTranslate:'program-tooltips' }}
                                    </li>
                                </ul>
                            </ng-container>
                            <ng-container *ngIf="trackType === enumTrackType.HARN">
                                <h3>{{ 'driver-info-title' | cduxTranslate:'program-tooltips' | uppercase }}</h3>
                                <ul>
                                    <li *ngIf="section === ViewSectionEnum.PROGRAM_BASIC">
                                        {{ 'driver-info-basic-detail' | cduxTranslate:'program-tooltips' }}
                                    </li>
                                    <li *ngIf="section === ViewSectionEnum.PROGRAM_ADVANCED">
                                        {{ 'driver-info-adv-detail' | cduxTranslate:'program-tooltips' }}
                                    </li>
                                </ul>
                            </ng-container>

                            <h3>{{ 'trainer-info-title' | cduxTranslate:'program-tooltips' | uppercase }}</h3>
                            <ul>
                                <ng-container *ngIf="section === ViewSectionEnum.PROGRAM_ADVANCED">
                                    <li [ngSwitch]="trackType">
                                        <ng-container *ngSwitchCase="enumTrackType.TBRED">
                                            {{ 'trainer-info-tbred-adv-detail' | cduxTranslate:'program-tooltips' }}
                                            <br>
                                            {{ 'trainer-stats-detail' | cduxTranslate:'program-tooltips' }}
                                        </ng-container>
                                        <ng-container *ngSwitchCase="enumTrackType.HARN">
                                            {{ 'trainer-info-harn-adv-detail' | cduxTranslate:'program-tooltips' }}
                                        </ng-container>
                                        <ng-container *ngSwitchCase="enumTrackType.GREY">
                                            {{ 'trainer-info-grey-adv-detail' | cduxTranslate:'program-tooltips' }}
                                        </ng-container>
                                    </li>
                                </ng-container>
                                <ng-container *ngIf="section === ViewSectionEnum.PROGRAM_BASIC">
                                    <li>{{ 'trainer-info-basic-detail' | cduxTranslate:'program-tooltips' }}</li>
                                </ng-container>
                            </ul>

                            <ng-container *ngIf="section === ViewSectionEnum.PROGRAM_ADVANCED">
                                <h3>{{ 'sire-dam-info-title' | cduxTranslate:'program-tooltips' | uppercase }}</h3>
                                <ul>
                                    <li>
                                        {{ 'sire-detail' | cduxTranslate:'program-tooltips' }}
                                        <br>
                                        {{ 'dam-detail' | cduxTranslate:'program-tooltips' }}
                                    </li>
                                </ul>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="classic-bet-bar-item" *ngIf="displayMode === DisplayModeEnum.LARGE && section === ViewSectionEnum.PROGRAM_SPEED">
            <div class="what-is program-speed-tooltip hover click">
                <span>
                    What is {{ trackType === enumTrackType.TBRED ? 'BRIS' : '' }} Speed?
                    <div class="icon--info"></div>
                </span>
                <div class="program-speed-tooltip-info tooltip-content">
                    <ng-container *ngIf="trackType === enumTrackType.TBRED">
                        <h2>SPEED</h2>
                        <div class="program-speed-info-graphic-revised">
                            <div class="program-speed-left">
                                <span>S</span><span>T</span><span>A</span><span>R</span><span>T</span></div>
                            <div class="program-speed-center">
                                <span>SPEED</span>
                                <div class="speed-bar"></div>
                            </div>
                            <div class="program-speed-right">
                                <span>F</span><span>I</span><span>N</span><span>I</span><span>S</span><span>H</span></div>
                        </div>

                        <h3>RUN STYLE</h3>
                        <ul class="program-run-style-list">
                            <li><span>E</span>Early Speed</li>
                            <li><span>E/P</span>Early Presser</li>
                            <li><span>P</span>Presser</li>
                            <li><span>S</span>Sustained Runner</li>
                        </ul>

                        <h3>SPEED POINTS</h3>
                        <ul class="program-speed-points-list">
                            <li><span>0-8</span>The higher the number the faster the horse is early in the race.</li>
                        </ul>

                        <h3>AVERAGE SPEED</h3>
                        <ul>
                            <li>Is an average of the speed ratings earned by a horse over the course of it’s last three starts.
                            </li>
                        </ul>

                        <h3>AVERAGE SPEED AT DISTANCE</h3>
                        <ul>
                            <li>Is an average of the speed and pace ratings for the horse specific today’s distance and surface
                                category.</li>
                        </ul>

                        <h3>BEST SPEED</h3>
                        <ul>
                            <li>Is the best speed for a horse at today’s distance and surface category.</li>
                        </ul>

                        <ul>
                            <li><span class="bris-ratings">BRIS Pace Ratings:</span> One length is approximately equal to 1 1/2
                                pts in sprints and approximately 1 point in routes.</li>
                        </ul>
                    </ng-container>
                    <ng-container *ngIf="trackType !== enumTrackType.TBRED">
                        <h2>SPEED</h2>
                        <h3>POWER RATING</h3>
                        <ul>
                            <li>Power Rating of the runner.</li>
                        </ul>
                        <h3>AVG SPEED</h3>
                        <ul>
                            <li>Average Speed Rating of the runner.</li>
                        </ul>
                        <h3>BEST SPEED</h3>
                        <ul>
                            <li>Best Speed Rating earned by the runner.</li>
                        </ul>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="classic-bet-bar-item" *ngIf="displayMode === DisplayModeEnum.LARGE && section === ViewSectionEnum.PROGRAM_CLASS">
            <div class="what-is program-class-tooltip hover click">
                <span>
                    What is {{ trackType === enumTrackType.TBRED ? 'BRIS' : '' }} Class?
                    <div class="icon--info"></div>
                </span>
                <div class="program-class-tooltip-info tooltip-content">
                    <h2>CLASS</h2>
                    <ng-container *ngIf="trackType === enumTrackType.TBRED">
                        <h3>PRIME POWER</h3>
                        <ul>
                            <li>Prime Power accurately measures the demonstrated quality of each horse's most recent starts and consolidates them into
                                one composite rating. Speed, Class, Pace, Form, Weight, and many more factors are combined to form this rating. The higher
                                the number the better.</li>
                        </ul>
                        <h3>AVERAGE CLASS</h3>
                        <ul>
                            <li>Measures a horse's actual performance in a race. The higher the number, the better the performance.</li>
                        </ul>
                        <h3>LAST CLASS</h3>
                        <ul>
                            <li>An average of the BRIS Class Rating for the last three starts regardless of distance and surface.</li>
                        </ul>
                        <ul>
                            <li><span class="bris-ratings">BRIS Pace Ratings:</span> A one point edge over a competitor is considered "significant" and an edge of two or more points is
                                considered a "substantial" advantage</li>
                        </ul>
                    </ng-container>
                    <ng-container *ngIf="trackType !== enumTrackType.TBRED">
                        <h3>POWER RATING</h3>
                        <ul>
                            <li>Power Rating of the runner.</li>
                        </ul>
                        <h3>LAST CLASS</h3>
                        <ul>
                            <li>Class Rating earned by the runner in its most recent start.</li>
                        </ul>
                        <h3>AVG CLASS</h3>
                        <ul>
                            <li>Average Class Rating of the runner.</li>
                        </ul>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="classic-bet-bar-item" *ngIf="displayMode === DisplayModeEnum.LARGE && section === ViewSectionEnum.PROGRAM_PACE">
            <div class="what-is program-pace-tooltip hover click">
                <span>
                    What is {{ trackType === enumTrackType.TBRED ? 'BRIS' : '' }} Pace?
                    <div class="icon--info"></div>
                </span>
                <div class="program-pace-tooltip-info tooltip-content">
                    <h2>PACE</h2>
                    <ng-container *ngIf="isTBNA">
                        <div class="program-pace-info-graphic-revised">
                            <div class="program-pace-left"><span>S</span><span>T</span><span>A</span><span>R</span><span>T</span></div>
                            <div class="program-pace-center">
                                <div class="early-pace-container">
                                    <div class="early-pace-1-container">
                                        <span>EARLY PACE 1</span>
                                        <div class="early-pace-1-bar"></div>
                                    </div>
                                    <div class="label">1ST CALL</div>
                                </div>
                                <div class="early-pace-container">
                                    <div class="early-pace-2-container">
                                        <span>EARLY PACE 2</span>
                                        <div class="early-pace-2-bar"></div>
                                    </div>
                                    <div class="label">2ND CALL</div>
                                </div>
                                <div class="early-pace-container-2">
                                    <div class="early-pace-3-container">
                                        <span>LAST PACE</span>
                                        <div class="early-pace-3-bar"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="program-pace-right"><span>F</span><span>I</span><span>N</span><span>I</span><span>S</span><span>H</span></div>
                        </div>
                        <h3>PACE RATINGS</h3>
                        <ul class="program-pace-ratings-list">
                            <li><span>E1</span>How fast a horse ran from the start to the 1st call, which is 2-furlongs in sprints and 4-furlongs in route races.</li>
                            <li><span>E2</span>How fast a horse ran from the start to the 2nd call, which is 4-furlongs in sprints and 6-furlongs in route races.</li>
                            <li><span>LP</span>How fast a horse ran from the 2nd call (pre-stretch call) to the finish.</li>
                        </ul>
                        <h3>RUN STYLE</h3>
                        <ul class="program-run-style-list">
                            <li><span>E</span>Early Speed</li>
                            <li><span>E/P</span>Early Presser</li>
                            <li><span>P</span>Presser</li>
                            <li><span>S</span>Sustained Runner</li>
                        </ul>
                        <h3>SPEED POINTS</h3>
                        <ul class="program-speed-points-list">
                            <li><span>0-8</span>The higher the number the faster the horse is early in the race.</li>
                        </ul>
                        <ul>
                            <li><span class="bris-ratings">BRIS Pace Ratings:</span> 2-points-per-length</li>
                        </ul>
                    </ng-container>
                    <ng-container *ngIf="!isTBNA">
                        <h3>EARLY PACE</h3>
                        <ul>
                            <li>Average position of this runner at the start of the race.</li>
                        </ul>
                        <h3>MID PACE</h3>
                        <ul>
                            <li>Average position of this runner in the middle of the race.</li>
                        </ul>
                        <h3>FINISH</h3>
                        <ul>
                            <li>Average position of this runner at the end of the race.</li>
                        </ul>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="classic-bet-bar-item"  *ngIf="section === ViewSectionEnum.PROGRAM_SUMMARY">
            <cdux-program-summary-glossary></cdux-program-summary-glossary>
        </div>

        <div class="classic-bet-bar-item angles-pick-bar-item" *ngIf="section === ViewSectionEnum.PROGRAM_TIPS">
            <cdux-angles-top-pick-bar [displayMode]="displayMode" [view]="view" [wagerState]="wagerState"
                (onSelection)="top3Selection($event)" [toteDate]="toteDate"></cdux-angles-top-pick-bar>
        </div>

        <div class="classic-bet-bar-item" *ngIf="isExpertPicksEnabled && hasExpertPick && section !== ViewSectionEnum.RUNNER_BALLOT" [hidden]="isExpertPicksHidden">
            <cdux-expert-pick-analysis [view]="view" [section]="section" [wagerState]="wagerState" [raceStatus]="raceStatus"
                (onSelection)="top3Selection($event, expertPicksComponent.wagerAmount)"></cdux-expert-pick-analysis>
        </div>

        <div class="classic-bet-bar-item expandable" *ngIf="isBrisPicksEnabled && hasBrisPick && section !== ViewSectionEnum.RUNNER_BALLOT" [hidden]="isBrisPicksHidden">
            <cdux-bris-picks [view]="view" [section]="section" [wagerState]="wagerState" [raceStatus]="raceStatus" [isCompact]="showCompactBrisPicks"
                (onSelection)="top3Selection($event, brisTipsComponent.wagerAmount)"></cdux-bris-picks>
        </div>
    </div>

    <ng-container *ngIf="section === ViewSectionEnum.RUNNER_BALLOT && displayMode === DisplayModeEnum.LARGE">
        <ng-container *ngIf="isWagerableRace(); else showClose">
            <cdux-runner-ballot-section #sectionWidget
                                        [raceStatus]="raceStatus"
                                        [wagerState]="wagerState"
                                        [entries]="entriesObs"
                                        (updateRaceNav)="updateRaceNav.emit($event)"
                                        (updateBetNav)="updateBetNav.emit($event)"
                                        (updateBetType)="updateBetType.emit($event)"
                                        (updateBetAmount)="updateBetAmount.emit($event)"
                                        (updateBetModifier)="updateBetModifier.emit($event)"
                                        (onSelectionChange)="onSelectionChange.emit($event)"
                                        (onAlternateSelectionChange)="onAlternateSelectionChange.emit($event)"
                                        (onResetEntrySelections)="onResetEntrySelections.emit($event)">
            </cdux-runner-ballot-section>
        </ng-container>
        <ng-template #showClose>
            <div class="has-error light">
                <div class="gbl-message">
                    <i class="icon&#45;&#45;info"></i>
                    <div class="header uppercase">betting is closed for this race</div>
                    <div *ngIf="isRaceClosed" (click)="updateSection.emit(ViewSectionEnum.RESULTS);" class="text-link uppercase cursor-pointer">View results</div>
                </div>
            </div>
        </ng-template>
    </ng-container>

    <ng-container *ngIf="(view === WageringViewEnum.VIDEO || view === WageringViewEnum.CLASSIC || view === WageringViewEnum.MOBILE) && section !== ViewSectionEnum.RUNNER_BALLOT">
        <cdux-program #sectionWidget
            [displayMode]="displayMode"
            [wager]="wagerState"
            [entries]="entriesObs"
            [section]="section"
            [view]="view"
            [viewableWagerData]="viewableData"
            (onAlternateSelectionChange)="onAlternateSelectionChange.next($event)"
            (onSelectionChange)="onSelectionChange.next($event)">
        </cdux-program>
    </ng-container>
</div>

<div class="scratches-container" *ngIf="showScratches && displayMode === DisplayModeEnum.LARGE">
    <cdux-changes-section [wagerState]="wagerState"></cdux-changes-section>
</div>
