import { Component, Output, EventEmitter, Input } from '@angular/core';
import { Observable } from 'rxjs';
import {
    enumRaceStatus,
    BasicBetType,
    MultiRaceExoticBetType,
    enumBetModifier,
    ISelectedBetAmount,
    ToteDataService,
    ITrackBasic,
} from '@cdux/ng-common';
import { AbstractWageringSectionComponent } from '../abstract-section.component';
import { IAlternateSelections, ISelectionUpdate } from 'app/shared/program/interfaces/selection-update.interface';
import { ViewStateService } from 'app/wagering/views/services/view-state.service';
import { IEntryUpdates } from 'app/shared/program/classes/entry-update-handler.class';

@Component({
    selector: 'cdux-runner-ballot-section',
    template: `
    <div class="classic-bet-bar">
        <div class="classic-bet-bar-item">
            <cdux-common-bet-nav
                [selectedRaceNav]="wagerState?.basicTrack"
                [selectedBetNav]="wagerState?.betNav"
                (availableBetTypes)="onBetNavChange($event)"
                (updateBetType)="onUpdateBetType($event)"
                (updateBetAmount)="onUpdateBetAmount($event)"
                (updateBetModifier)="onUpdateBetModifier($event)">
            </cdux-common-bet-nav>
        </div>
        <div class="classic-bet-bar-item">
            <cdux-runner-bar class="full-size"
                [wagerState]="wagerState"
                [raceStatus]="raceStatus"
                (onClear)="onResetEntrySelections.emit($event)">
            </cdux-runner-bar>
        </div>
    </div>
    <cdux-carryover-banner [track]="wagerState?.basicTrack" (updateRaceNav)="updateRaceNav.emit($event)" (updateBetType)="onUpdateBetType($event)"></cdux-carryover-banner>
    <cdux-runner-ballot 
        [wager]="wagerState" 
        [entries]="entries" 
        [betTypesAvailable]="betTypesAvailable" 
        (onAlternateSelectionChange)="onAlternateSelectionChange.next($event)"
        (onSelectionChange)="onSelectionChange.emit($event)">
    </cdux-runner-ballot>
    `,
    styleUrls: ['./runner-ballot-section.component.scss']
})
export class RunnerBallotSectionComponent extends AbstractWageringSectionComponent {

    @Input()
    public entries: Observable<IEntryUpdates>;

    @Input()
    public raceStatus: enumRaceStatus;

    @Output()
    public onSelectionChange: EventEmitter<ISelectionUpdate[]> = new EventEmitter<ISelectionUpdate[]>();

    @Output()
    public onResetEntrySelections: EventEmitter<undefined> = new EventEmitter<undefined>();

    @Output() public updateRaceNav = new EventEmitter<ITrackBasic>();
    @Output() public updateBetNav: EventEmitter<(BasicBetType[] | MultiRaceExoticBetType[])> = new EventEmitter<(BasicBetType[] | MultiRaceExoticBetType[])>();
    @Output() public updateBetType: EventEmitter<BasicBetType | MultiRaceExoticBetType> = new EventEmitter<BasicBetType | MultiRaceExoticBetType>();
    @Output() public updateBetAmount: EventEmitter<ISelectedBetAmount> = new EventEmitter<ISelectedBetAmount>();
    @Output() public updateBetModifier: EventEmitter<enumBetModifier> = new EventEmitter<enumBetModifier>();
    @Output() public onAlternateSelectionChange: EventEmitter<IAlternateSelections> = new EventEmitter<IAlternateSelections>();

    public betTypesAvailable: boolean = true;

    constructor(protected _toteDataService: ToteDataService,
        protected _viewStateService: ViewStateService) {
        super(_toteDataService, _viewStateService);

    }

    public onUpdateBetType(betType: (BasicBetType | MultiRaceExoticBetType)) {
        this.updateBetType.emit(betType);
    }

    public onUpdateBetModifier(betModifier: enumBetModifier) {
        this.updateBetModifier.emit(betModifier);
    }

    public onUpdateBetAmount(betAmount: ISelectedBetAmount) {
        this.updateBetAmount.emit(betAmount);
    }

    public onBetNavChange(betNav: (BasicBetType | MultiRaceExoticBetType)[]) {
        this.betTypesAvailable = !!betNav && betNav.length > 0;
        this.updateBetNav.emit(betNav);
    }
}
