import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { AccountBubbleNotificationService } from '../../../notification/services/account-bubble-notification.service';
import { AbstractMenuItemComponent } from '../abstract-menu-item.component';
import { EventTrackingService } from '../../../event-tracking/services/event-tracking.service';
import { FavEventType } from 'app/account/favorites/favorites-event-interface';
import { SidebarService } from '../../../sidebar/sidebar.service';
import { BetSlipBusinessService } from '../../../bet-slip/services/bet-slip.business.service';
import { MenuItemsEnum } from '../../enums/menu-items.enum';
import { DrawerService, FullDrawerOperations, FullDrawerOriginDirection, ActiveDrawerEnum } from 'app/shared/common/services';
import { InboxFeedComponent } from './inbox-feed/inbox-feed.component';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { EventClickType } from '@cdux/ng-common';
import { TournamentsSessionService } from 'app/shared/tournaments-session/services/touranments-session.service';
import { InboxService } from '../../services/inbox.service';

const INBOX_MENU_ITEM = 'inbox_menu_item';

@Component({
    selector: 'cdux-inbox-menu-item',
    templateUrl: './inbox-menu-item.component.html'
})
export class InboxMenuItemComponent extends AbstractMenuItemComponent implements AfterViewInit, OnInit, OnDestroy {
    public readonly INBOX_MENU_ITEM_WAYPOINT_KEY: string = INBOX_MENU_ITEM;
    protected navTarget = MenuItemsEnum.INBOX;
    protected clickEvent = EventClickType.MAIN_NAV_MENU_INBOX;

    private _destroy$ = new Subject<void>();

    public hasNotifications = false;
    public isPhone: boolean;
    protected _isDrawerOpen = false;
    public isTournamentSelected: boolean;
    isOpen$ = this._inboxService.isInboxOpen();

    constructor(
        protected _drawerService: DrawerService,
        protected _sidebarService: SidebarService,
        protected _betSlipService: BetSlipBusinessService,
        protected _eventTrackingService: EventTrackingService,
        private _mediaQuery: CduxMediaToggleService,
        private _accountBubbleNotificationService: AccountBubbleNotificationService,
        private _tournamentSessionService: TournamentsSessionService,
        private _inboxService: InboxService
    ) {
        super(_sidebarService, _eventTrackingService, _drawerService);
    }

    ngOnInit() {
        this.isTournamentSelected = this._tournamentSessionService.isTournamentSelected();
        this._initializePhoneMediaQuery();
        this._initializeNotifications();
        this._subscribeToDeleteAllEvents();
        super.subscribeToDrawerState();
        // initialize inbox when component is first rendered on landing page.
        // We then grab any cached cards so that the feed will be populated with cards before a user opens the component.
        this._inboxService.initializeInbox();

    }

    ngAfterViewInit() {
        this._checkUrlForInboxAction();
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
        super.ngOnDestroy();
    }

    public toggleFeed() {
        this._isDrawerOpen ? this.closeInboxFeed() : this.openInboxFeed();
    }

    protected activate(): void {
        this.toggleFeed();
    }

    protected deactivate(): void {
        this.toggleFeed();
    }

    public openInboxFeed() {
        this._inboxService.openInbox();
        this._drawerService.activeDrawer = ActiveDrawerEnum.INBOX;
        this._drawerService.toggleFullDrawer({
            component: InboxFeedComponent,
            operation: FullDrawerOperations.OPEN,
            originDirection: FullDrawerOriginDirection.RIGHT,
            title: 'Inbox',
            closeOnBackdropClick: true
        });
        this._sidebarService.close(true);
    }

    public closeInboxFeed() {
        this._inboxService.closeInbox();
        this._drawerService.activeDrawer = ActiveDrawerEnum.NONE;
        this._drawerService.toggleFullDrawer({
            operation: FullDrawerOperations.CLOSE,
            originDirection: FullDrawerOriginDirection.RIGHT
        });
    }

    private _initializePhoneMediaQuery(): void {
        this._mediaQuery.registerQuery('phone')
            .pipe(takeUntil(this._destroy$))
            .subscribe((isPhone: boolean) => this.isPhone = isPhone);
    }

    private _initializeNotifications(): void {
        this._inboxService.hasNotifications$
            .pipe(takeUntil(this._destroy$))
            .subscribe(
                hasNotifications => this.hasNotifications = hasNotifications,
                error => console.error('Error fetching notifications:', error)
            );
    }

    private _subscribeToDeleteAllEvents(): void {
        this._accountBubbleNotificationService.on([FavEventType.INBOX_CONTENT_CARD_DELETE_ALL])
            .pipe(takeUntil(this._destroy$))
            .subscribe((deleteEvent) => {
                if (deleteEvent) {
                    this.hasNotifications = false;
                }
            });
    }

    private _checkUrlForInboxAction(): void {
        const url = new URL(location.href);
        const actionParam = url.searchParams.get('action');
        if (actionParam === 'inbox') {
            this.openInboxFeed();
        }
    }
}
