import { APP_INITIALIZER, NgModule } from '@angular/core';
import { TitleCasePipe } from '@angular/common'
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { PortalModule } from '@angular/cdk/portal';

import {
    ENVIRONMENT,
    CduxDataServicesModule,
    AFFIDS
} from '@cdux/ng-core';
import {
    CduxNgCommonModule,
    DetectionService,
    EventsService,
    FeatureToggleDataService,
    TrackService,
    TranslateModule,
    EventSessionModule,
    EventSessionInterceptor,
    TranslateService,
    WagerModule,
    LOCAL_DICTIONARY,
    PlatformService,
    JwtSessionInterceptor,
    WindowRefService,
    MultiSessionService,
    EVENT_TRACKING,
    FEATURE_ENV_TOGGLE_OVERRIDES,
} from '@cdux/ng-common';
import {
    CduxModalModule,
    CduxNotificationBubbleModule,
    CduxStorageModule
} from '@cdux/ng-platform/web';

// shared typescript classes
import { BrazeUtils } from '@cdux/ts-domain-braze';

// Provided for DI
/* eslint-disable no-restricted-imports */
import { environment } from 'environments/environment';
/* eslint-enable no-restricted-imports */

import { FEATURE_TOGGLE_OVERRIDE_CONFIG } from 'feature-environment/feature-toggle-override-config';

// Service for initApp
import { ApplicationInitializationService } from './app.init.service';

// Translation Dictionary
import { LANGUAGE_DICTIONARY } from '../languages';

// Root Component
import { AppComponent } from './app.component';
// Root Routes
import { AppRoutingModule } from './app.routing.module';

// Shared Modules
import { AccountHistoryModule } from './account/account-history/account-history.module';
import { BreadcrumbsModule } from './shared/breadcrumbs/breadcrumbs.module';
import { CarouselModule } from './shared/carousel/carousel.module';
import { DatepickerModule } from './shared/datepicker/datepicker.module';
import { EventTrackingModule } from './shared/event-tracking/event-tracking.module';
import { FavoritesModule } from 'app/account/favorites/favorites.module';
import { FundingModule } from './shared/funding/funding.module';
import { HeaderModule } from './shared/header/header.module';
import { InterceptorModule } from './shared/interceptor/interceptor.module';
import { LayoutModule } from './shared/layout/layout.module';
import { MyAccountModule } from './shared/my-account/my-account.module';
import { NotificationModule } from './shared/notification/notification.module';
import { RadioModule } from './shared/radio/radio.module';
import { RedirectModule } from './shared/redirect/redirect.module';
import { RegistrationModule } from './shared/registration/registration.module';
import { ScrollableContentModule } from './wagering/shared/scrollable-track/scrollable-content.module';
import { SsnCollectionModule } from './shared/ssn-collection/ssn-collection.module';
import { TermsModule } from './shared/terms/terms.module';
import { TransactionModule } from './shared/transaction/transaction.module';
import { TuxCommonModule } from './shared/common/common.module';
import { TuxEcommerceModule } from './shared/ecommerce/ecommerce.module';
import { UpNextModule } from './shared/up-next/up-next.module';
import { ProgramSearchModule } from './shared/program-search/program-search.module';

// HTTP Interceptor Services
import { BetSlipModule } from './shared/bet-slip/bet-slip.module';
import { SidebarModule } from './shared/sidebar/sidebar.module';
import { BetsModule } from './shared/bets/bets.module';

import { BetPadSharedModule } from './shared/betpad/betpad-shared.module';
import { BetPadWindowGuard } from 'app/bet-pad-window.guard';
import { MenuItemsModule } from './shared/menu-items/menu-items.module';
import { ProgramSharedModule } from './shared/program/program-shared.module';
import { VideoModule } from './wagering/video/video.module';
import { WageringViewsModule } from './wagering/views/views.module';

// Platform Modules
import { PlatformRegistrationModule } from './platform/registration/platform-registration.module';
import { EventTrackingService } from './shared/event-tracking/services/event-tracking.service';
import { ZENDESK_URL } from '@cdux/ng-fragments';
import { TournamentInterceptor } from './shared/tournaments-session/interceptor/tournaments.interceptor';
import { TournamentsSessionService } from './shared/tournaments-session/services/touranments-session.service';

@NgModule({
    bootstrap: [AppComponent],
    imports: [
        AccountHistoryModule,
        BetsModule,
        BrowserAnimationsModule,
        BrowserModule,
        CduxDataServicesModule,
        CduxModalModule,
        CduxNotificationBubbleModule,
        CduxNgCommonModule,
        EventSessionModule,
        FavoritesModule,
        PortalModule,
        ProgramSearchModule,
        ScrollableContentModule,
        TranslateModule,
        PortalModule,
        // Begin: Shared Modules
        BreadcrumbsModule.forRoot(),
        CarouselModule.forRoot(),
        TuxCommonModule.forRoot(),
        DatepickerModule.forRoot(),
        TuxEcommerceModule.forRoot(),
        EventTrackingModule.forRoot(),
        FundingModule.forRoot(),
        HeaderModule.forRoot(),
        InterceptorModule.forRoot(),
        LayoutModule.forRoot(),
        MenuItemsModule.forRoot(),
        MyAccountModule.forRoot(),
        RadioModule.forRoot(),
        RedirectModule.forRoot(),
        RegistrationModule.forRoot(),
        SidebarModule.forRoot(),
        SsnCollectionModule.forRoot(),
        TermsModule.forRoot(),
        TransactionModule.forRoot(),
        UpNextModule.forRoot(),
        BetSlipModule.forRoot(),
        BetPadSharedModule,
        NotificationModule.forRoot(),
        ProgramSharedModule,
        // End: Shared Module
        AppRoutingModule,
        WagerModule,
        VideoModule.forRoot(),
        CduxStorageModule,
        WageringViewsModule,
        // Begin: Platform Modules
        PlatformRegistrationModule.forRoot(),
        // End: Platform Modules
    ],
    declarations: [
        AppComponent
    ],
    providers: [
        {
            provide: LOCAL_DICTIONARY,
            useValue: LANGUAGE_DICTIONARY
        },
        {
            provide: BrazeUtils,
            useFactory: () => {
                return new BrazeUtils();
            }
        },
        ApplicationInitializationService,
        DetectionService,
        EventsService,
        BetPadWindowGuard,
        FeatureToggleDataService,
        MultiSessionService,
        TitleCasePipe,
        TrackService,
        TranslateService,
        {
            provide: ENVIRONMENT,
            useFactory: (windowRef: WindowRefService, detectionService: DetectionService, platformService: PlatformService): ENVIRONMENT => {
                const appConfig = { ...environment.appConfig }; // extend default app config
                // update appConfig username to reflect platform for BI on API calls
                if (windowRef.nativeWindow.location.pathname.includes('betpad')) {
                    appConfig.username = 'tux_betpad';
                } else if (platformService.isNativeApp()) {
                    // ensure API requests (such as featureToggles) which use
                    // username and/or platformId are also updated to reflect the native app
                    appConfig.username = platformService.getPlatformIdentifier();
                    appConfig.platformId = appConfig.username
                } else if (detectionService.isTablet()) {
                    appConfig.username = 'tux_tablet';
                } else if (detectionService.isMobileDevice()) {
                    // for other mobile devices
                    appConfig.username = 'tux_mobile';
                }
                return { ...environment, appConfig };
            },
            deps: [ WindowRefService, DetectionService, PlatformService ]
        },
        {
            provide: ZENDESK_URL,
            useFactory: (env: ENVIRONMENT): string => {
                let url: string;
                //  currentEnv comes from the POST_BUILD_ENVIRONMENT
                //  variable in ~/build/environments/<aff-id>/environment.<env>.js
                //  and is set by the post build process.
                //  We have to use the currentEnv because builds in CodePipeline for STE use production configs when building
                //  For Zendesk Messenger, we use a lower environment URL for all environments except PRODUCTION
                //  So, in order to test in STE we must check for this varaible which is set in the index.html file during the post build
                const currentEnvironment = typeof currentEnv !== 'undefined' ? currentEnv : 'DEV';

                if (currentEnvironment !== "PRODUCTION") {
                    switch(env.affiliateId) {
                        case AFFIDS.OAKLAWNANYWHERE:
                            url = 'https://static.zdassets.com/ekr/snippet.js?key=d17d62ea-ce1a-4423-a216-ea51d3246c33';
                            break;
                        case AFFIDS.TWINSPIRES:
                            url = 'https://static.zdassets.com/ekr/snippet.js?key=0a795583-f449-4a8c-9579-fd58f74a972e';
                            break;
                        case AFFIDS.BETAMERICA:
                            url = 'https://static.zdassets.com/ekr/snippet.js?key=f4c99685-7b82-40a9-b544-a016ac1b64a1';
                            break;
                        case AFFIDS.KEENELANDSELECT:
                            url = 'https://static.zdassets.com/ekr/snippet.js?key=6df5ef78-6f8c-4349-a4df-4c743c78b716';
                            break;
                        case AFFIDS.CAPITALOTBET:
                            url = 'https://static.zdassets.com/ekr/snippet.js?key=e0a1f6a8-4663-48b3-91b2-4eeac4e42fd5';
                            break;
                        case AFFIDS.DKHORSE:
                            url = 'https://static.zdassets.com/ekr/snippet.js?key=e883ce5c-57b1-4b6d-9b04-13ae19906197';
                            break;
                        default:
                            url = 'https://static.zdassets.com/ekr/snippet.js?key=0a795583-f449-4a8c-9579-fd58f74a972e';
                    }
                } else {
                    url = env.thirdPartyConfig.ZendeskChatURL;
                }
                return url;
            },
            deps: [ENVIRONMENT]
        },
        {
            provide: FEATURE_ENV_TOGGLE_OVERRIDES,
            useValue: FEATURE_TOGGLE_OVERRIDE_CONFIG
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtSessionInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: EventSessionInterceptor,
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: svc => () => svc.initialize(),
            deps: [ ApplicationInitializationService ],
            multi: true
        },
        {
            provide: EVENT_TRACKING,
            useExisting: EventTrackingService
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TournamentInterceptor,
            multi: true,
            deps: [ TournamentsSessionService ]
        },
    ],
    exports: [
        AppRoutingModule,
    ]
})
export class AppModule { }
