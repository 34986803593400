import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import { Subject, Observable, ReplaySubject, combineLatest } from 'rxjs';
import { map, filter, distinctUntilChanged, takeUntil, take } from 'rxjs/operators';
import {
    enumBetModifier,
    ISelectedEntry,
    ITrackBasic,
    ProgramEntry,
    TrackService,
    IBetNavObject,
    EntrySelectionUtilBusinessService,
    BetTypeUtil,
    WagerState,
    ToteDataService,
    EventClickAttributeType,
    EventClickType,
    FeatureToggleDataService,
    enumFeatureToggle
} from '@cdux/ng-common';
import { ISelection } from '@cdux/ng-fragments';
import { ISelectionUpdate, IAlternateSelected, IAlternateSelections } from 'app/shared/program/interfaces/selection-update.interface';
import { ISelectedProgramNumber } from 'app/shared/betpad/interfaces/selected-program-number.interface';
import { IEntryMap } from './interfaces/selected-entry.interface';
import { RunnerBallotEntriesService } from './services/runner-ballot-entries.service';
import { IEntryUpdates } from 'app/shared/program/classes/entry-update-handler.class';
import { ILegInfo } from 'app/shared/program/interfaces/leg-info.interface';
import { WageringUtilBusinessService } from 'app/shared/program/services/wagering-util.business.service';
import { LegInfoUtil } from 'app/shared/program/utils';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';


@Component({
    selector: 'cdux-runner-ballot',
    templateUrl: './runner-ballot.component.html',
    styleUrls: ['./runner-ballot.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class RunnerBallotComponent implements OnInit, OnDestroy {
    private _wagerState: WagerState;
    @Input()
    public set wager(wager: WagerState) {
        if (wager) {
            if (!TrackService.isExactTrackObject(this.track, wager.basicTrack)) {
                // change entries, clear selections for new track or race
                this.track = wager.basicTrack;
                this.selectAllLeg();
            }
            this.isKey = !!wager.betNav ? (wager.betNav.modifier === enumBetModifier.KEY) : false;
            this._wager.next(wager);
            this._wagerState = wager;
        }
    }
    public get wager(): WagerState {
        return this._wagerState;
    }

    @Input()
    public entries: Observable<IEntryUpdates>;

    @Input()
    public betTypesAvailable: boolean;

    @Output()
    onSelectionChange: EventEmitter<ISelectionUpdate[]> = new EventEmitter<ISelectionUpdate[]>();

    @Output() onAlternateSelectionChange: EventEmitter<IAlternateSelections> = new EventEmitter<IAlternateSelections>();


    public condensedEntries: IEntryMap[][] = [];
    private _multiLegRaceEntries: ProgramEntry[][] = [];
    public saddleCloths: number[];

    public selectedAlternatedBettingInterest: IAlternateSelected[]  = [];
    public selectedProgramNumbers: ISelectedProgramNumber = {};
    public track: ITrackBasic;
    public areAllSelected: boolean[] = [];
    public isKey: boolean = false;
    public legInfo: ILegInfo;
    public filteredLeg: number = null;
    public visibleTooltip: number;
    public selectionEnabled: boolean;
    private _selectedBettingInterests: ISelectedEntry[][];
    private _toteDate: string;
    private _showAlternatePicks: boolean;
    private _destroy: Subject<undefined> = new Subject<undefined>();

    // Subjects
    private _wager: ReplaySubject<WagerState> = new ReplaySubject<WagerState>();

    constructor(
        private _runnerBallotService: RunnerBallotEntriesService,
        private _entrySelectionService: EntrySelectionUtilBusinessService,
        private _wageringUtilService: WageringUtilBusinessService,
        private _changeDetector: ChangeDetectorRef,
        private _eventService: EventTrackingService,
        private _toteDataService: ToteDataService,
        private _featureToggleService: FeatureToggleDataService,

    ) {}

    @HostListener('document:touchstart', ['$event']) touchStart(event: Event) {
        this.toggleTooltipOnClick(null);
    }

    /** LIFECYCLE HOOKS **/
    ngOnInit(): void {
        this.entries.subscribe((legRaceEntriesUpdate: IEntryUpdates) => {
            this.selectedAlternatedBettingInterest = legRaceEntriesUpdate.wagerState.alternateBettingInterests;
            this._multiLegRaceEntries = legRaceEntriesUpdate.updatedEntries;
            this._selectedBettingInterests = legRaceEntriesUpdate.bettingInterests;
            this.selectedProgramNumbers = this._wageringUtilService.getSelectedProgramNumbers(legRaceEntriesUpdate.bettingInterests, 'BettingInterest');
            this.legInfo = LegInfoUtil.assembleLegInfo(legRaceEntriesUpdate.wagerState.betNav);
            this.condensedEntries = this._runnerBallotService.mapSelectedEntries(legRaceEntriesUpdate.updatedEntries, this.legInfo);
            this.saddleCloths = this._runnerBallotService.generateSaddleClothList(this.condensedEntries, this.legInfo.multiRace);
            this.areAllSelected = legRaceEntriesUpdate.allSelected;
            if (legRaceEntriesUpdate.scratches.length > 0) {
                this.onSelectionChange.emit(legRaceEntriesUpdate.scratches);
            }
            this._changeDetector.detectChanges();
        });

        this._getBetNavStream().pipe(distinctUntilChanged((prev, curr) => {
            return BetTypeUtil.isSameWagerType(prev.type, curr.type);
        }), takeUntil(this._destroy)).subscribe(val => {
            this.selectAllLeg();
            this._changeDetector.detectChanges();
        });

        this._disableUserInteractionSub(this.entries);

        this._toteDataService.currentRaceDate().pipe(take(1)).subscribe((date) => {
            this._toteDate = date;
        });
        this._showAlternatePicks = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.PICK_ALTERNATES);

    }

    ngOnDestroy(): void {
        this._wager.complete();
        this._destroy.next();
        this._destroy.complete();
    }
    /** END LIFECYCLE HOOKS **/

    public getDisabled(entry: IEntryMap , leg: number): boolean {
        if (!entry.bettingInterest || entry.scratched) {
            return true;
        }
        if (this.isKey && leg === 1) {
            return this._entrySelectionService.isBettingInterestSelected(this._selectedBettingInterests, 0, entry.bettingInterest);
        }
        return false;
    }

    public isScratched(index: number)   {
        return (!this.legInfo.multiRace && this.condensedEntries.length === 1 && this.condensedEntries[0][index]?.scratched);
    }

    /** OBSERVABLE FACTORIES **/
    private _getBetNavStream(): Observable<IBetNavObject> {
        return this._wager.pipe(
            map((wagerState) => wagerState.betNav),
            filter(betNav => !!betNav)
       );
    }
    /** END OBSERVABLE FACTORIES **/

    public selectRunner(data: ISelection, bettingInterest: number, leg: number) {
        const entries = this._wageringUtilService.getEntriesForLeg(this._multiLegRaceEntries, this.legInfo, leg)
            .filter(entry => entry.BettingInterest && entry.BettingInterest === bettingInterest && !entry.Scratched)
            .map(entry => this._wageringUtilService.toSelectedEntry(entry));
        if (entries.length > 0) {
            this.onSelectionChange.emit([{entries: entries, selected: data.isSelected, leg: leg}]);
        }

        if (this._showAlternatePicks && data.isSelected && this._isAlternateSelected(leg, bettingInterest)) {
            this.onAlternateSelectionChange.emit({alternateBettingInterest: bettingInterest, leg, selected: false});
        }
    }

    public selectAll(data: ISelection, leg: number) {
        const entries = this._wageringUtilService.getEntriesForLeg(this._multiLegRaceEntries, this.legInfo, leg)
            .filter(entry => entry.BettingInterest && !entry.Scratched)
            .map(entry => this._wageringUtilService.toSelectedEntry(entry));
        if (entries.length > 0) {
            this.onSelectionChange.emit([{entries: entries, selected: data.isSelected, leg: leg}]);
        }

        // unselect any alternate runner (if there is one)
        if (this._showAlternatePicks && this.selectedAlternatedBettingInterest) {
            const res = this._getSelectedAlternateRunner(leg);
            res && this.onAlternateSelectionChange.emit({
                alternateBettingInterest: res.alternateBettingInterest,
                leg,
                selected: false
            });
        }


        if (this.areAllSelected[leg]) {
            this._eventService.logClickEvent(
                EventClickType.SELECT_ALL_RUNNERS,
                [
                    { attrId: EventClickAttributeType.SELECT_ALL_RUNNERS_BRIS_CODE, data: this.wager.basicTrack.BrisCode },
                    { attrId: EventClickAttributeType.SELECT_ALL_RUNNERS_TRACK_TYPE, data: this.wager.basicTrack.TrackType },
                    { attrId: EventClickAttributeType.SELECT_ALL_RUNNERS_RACE_NUMBER, data: this.wager.basicTrack.RaceNum },
                    { attrId: EventClickAttributeType.SELECT_ALL_RUNNERS_RACE_DATE, data: this._toteDate }
                ]
            )
        }
    }

    public trackByBettingInterest(index: number, bettingInterest: IEntryMap): number {
        return bettingInterest.bettingInterest;
    }

    public trackByIndex(index: number): number {
        return index;
    }

    public getTooltipInfo(bettingInterest: string | number): {name: string, scratched: boolean, label: string}[] {
        const arr = [];
        this._multiLegRaceEntries.forEach((leg, index) => {
            const entries = leg.filter(entry => entry.BettingInterest === bettingInterest || entry.ProgramNumber === bettingInterest);
            if (this.legInfo.multiRace) {
                if (this.filteredLeg === null || this.filteredLeg === index) {
                    if (entries.length === 0) {
                        // apply true to scratched for css purposes
                        arr.push({name: 'N/A', scratched: true, label: this.legInfo.legsCounter[index] + ':'});
                    } else {
                        entries.forEach(entry => {
                            let label = this.legInfo.legsCounter[index] + ':';
                            if (entries.length > 1) {
                                label += ' ' + entry.ProgramNumber;
                            }
                            arr.push({name: entry.Name, scratched: entry.Scratched, label});
                        });
                    }
                }
            } else {
                entries.forEach(entry => {
                    const label = entries.length > 1 ? entry.ProgramNumber + ':' : null;
                    arr.push({name: entry.Name, scratched: entry.Scratched, label});
                });
            }
        });
        return arr;
    }

    public filterLeg(leg: number) {
        this.filteredLeg = leg
    }

    public selectAllLeg() {
        this.filteredLeg = null;
    }

    public toggleTooltipOnClick(index: number) {
        if (this.visibleTooltip !== index) {
            this.visibleTooltip = index;
        } else {
            this.visibleTooltip = null;
        }
    }

    public toggleTooltipOnHover(index: number, onHover: boolean) {
        if (this.visibleTooltip !== index || !onHover) {
            this.visibleTooltip = null;
        }
    }

    /* when a track/race changes or wager type changes, there is period when the view
     * and wager state are out of sync. Temporarily disable user interaction unti the
     * the new entries or reformatted selections have been received and the view has re-rendered
     */
    private _disableUserInteractionSub(entryObs: Observable<IEntryUpdates>) {
        combineLatest([this._wager, entryObs])
            .pipe(takeUntil(this._destroy))
            .subscribe(([wagerState, allEntriesUpdate]) => {
                const emittedWagerState = allEntriesUpdate.wagerState;
                this.selectionEnabled = TrackService.isExactTrackObject(wagerState.basicTrack, emittedWagerState.basicTrack)
                    && (!!wagerState.betNav && !!emittedWagerState.betNav)
                    && this._sameBetNav(wagerState.betNav, emittedWagerState.betNav);
            });
    }

    private _sameBetNav(betNav1: IBetNavObject, betNav2: IBetNavObject) {
        return BetTypeUtil.isSameWagerType(betNav1.type, betNav2.type) && betNav1.modifier === betNav2.modifier;
    }

    private _isAlternateSelected(legNumber: number, bettingInterest: number) {
        return Array.isArray(this.selectedAlternatedBettingInterest) && this.selectedAlternatedBettingInterest.find(item =>
            item.leg === legNumber && item.alternateBettingInterest === bettingInterest
        );
    }

    private _getSelectedAlternateRunner(legNumber: number): IAlternateSelected | undefined {
        return this.selectedAlternatedBettingInterest?.find(item => item.leg === legNumber);
    }
}
