import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { iif, Observable, of, Subscription } from 'rxjs';

import { CduxRequestError } from '@cdux/ng-core';
import { enumFeatureToggle, FeatureToggleDataService } from '@cdux/ng-common';
import {
    LoadingService,
    LoadingDotsComponent,
    IWager,
    StatusCodes,
    ErrorReasons
} from '@cdux/ng-fragments';

import { CompletedBetsBusinessService } from '../../services/completed-bets.business.service';
import { SsnCollectionService } from '../../../ssn-collection/services/ssn-collection.service';
import { enumProgramViews } from 'app/shared/program/enums/program-views.enum';
import { map, switchMap, take } from 'rxjs/operators';
import { TournamentsSessionService } from 'app/shared/tournaments-session/services/touranments-session.service';
import { ENVIRONMENT } from '@cdux/ng-core';

const MIN_OVERLAY_DURATION = 1000; // ms

@Component({
    selector: 'cdux-completed-bets-container',
    templateUrl: './completed-bets-container.template.html',
    styleUrls: ['./completed-bets-container.component.scss']
})
export class CompletedBetsContainerComponent implements OnInit, OnDestroy {
    @Output() public contract: EventEmitter<undefined> = new EventEmitter<undefined>();
    @Output() public initialized: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input() public programView: enumProgramViews;

    @Input() public todaysBetsContainer: boolean = false;

    private completedBetsSub: Subscription;
    private betShareDetailsSub: Subscription;
    public isFinishOrderToggledOn = true;
    public isVideoToggledOn = false;
    public isIVRPhoneMessageToggledOn = false;

    public completedBetsList = [];
    public isLoaded = false;
    public loadingDotsComponent = LoadingDotsComponent;
    public isError: boolean = false;
    public comBetsErrorMessage: string;
    public comBetsErrorMessageHeader: string;
    public hasFullSsn: boolean = false;
    public affiliateId: number;

    public isTournamentSelected: boolean;

    constructor(private completedBetsService: CompletedBetsBusinessService,
                private _environment: ENVIRONMENT,
                private _loadingService: LoadingService,
                private featureToggleService: FeatureToggleDataService,
                private ssnCollectionService: SsnCollectionService,
                private _completedBetsBusinessService: CompletedBetsBusinessService,
                private _tournamentSessionService: TournamentsSessionService

            ) {
        this.affiliateId = this._environment.affiliateId;
    }

    public ngOnInit() {
        this.isTournamentSelected = this._tournamentSessionService.isTournamentSelected();
        this._loadingService.register('completedBetsOverlay');
        // We want to force the cache to refresh if we have split the bet buttons. However, we will then need to subscribe to the cache to listen for any changes while the menu item is open.
        // It's a little weird to basically ignore the response from getTodaysCompletedBets and call the cache, but whatever.
        const completedBetsObs: Observable<any> = this.todaysBetsContainer ? this.completedBetsService.getTodaysCompletedBets() : this.completedBetsService.getCompletedBets();
        this.completedBetsSub = completedBetsObs.pipe(
            take(1),
            switchMap(
                (bets) => iif(
                    () => this.todaysBetsContainer,
                    this._completedBetsBusinessService.getTodaysCompletedBetsCache().pipe(
                        map((completedBets) => {
                            return completedBets.map((bet) => {
                                // Since we are ultimately returning the cached bets, we lose the Date objects to serialization. Get 'em back.
                                return Object.assign({}, bet, {eventDate: new Date(bet.eventDate), activityDate: new Date(bet.activityDate)});
                            })
                        })
                    ),
                    of(bets)
                )
            )
        ).subscribe(
            (results) => {
                if (this.isTournamentSelected) {
                    results = results.filter((wager) => wager.wagerTypeId === "TOURNAMENT");
                }
                // if in a non-tournament account, filter out any tournament wagers
                if (!this.isTournamentSelected) {
                    results = results.filter((wager) => !wager.wagerTypeId || wager.wagerTypeId !== "TOURNAMENT");
                }
                // If we have an IWager[] from refreshTodaysCompletedBets we need to group them in to BetListBetsModels
                // NOTE -- completedBetsService.createBetListBetsModel does an array shift so make a copy so we don't much with the original array
                const completedBets = Array.isArray(results) && results.length && !('bets' in results[0]) ? this.completedBetsService.createBetListBetsModel([...results] as IWager[]) : results;

                /*
                 * Overlay will display a minimum of one animation cycle
                 * Set initialization code in timeout with same amount of
                 * time so it does appear underneath animation
                 */
                this._loadingService.resolve('completedBetsOverlay', MIN_OVERLAY_DURATION, 'success');
                setTimeout(() => {
                    this.initialized.emit(true);

                    this.completedBetsList = completedBets;
                    this.isLoaded = true;
                    this.contractSize();
                }, MIN_OVERLAY_DURATION);
            },
            (response: CduxRequestError) => {
                // look at data object of error to determine if this is a waiting room response, if so show waiting room
                this._loadingService.resolve('completedBetsOverlay', -1);
                this.initialized.emit(true);
                this.isError = true;
                if (response.data.status === StatusCodes.WAITING_ROOM_DISPLAY_CODE) {  // agreed upon status code with
                    // prod engineering
                    this.comBetsErrorMessage = ErrorReasons.WAITING_ROOM;
                    this.comBetsErrorMessageHeader = ErrorReasons.WAITING_ROOM_HEADER;
                } else {
                    this.comBetsErrorMessage = ErrorReasons.ERROR_MESSAGE;
                    this.comBetsErrorMessageHeader = ErrorReasons.ERROR_MESSAGE_HEADER;
                }
            }
        );

        this.hasFullSsn = this.ssnCollectionService.hasFullSsn();
        this.isFinishOrderToggledOn = this.featureToggleService.isFeatureToggleOn(enumFeatureToggle.FINISH_ORDER);
        this.isVideoToggledOn = this.featureToggleService.isFeatureToggleOn(enumFeatureToggle.BET_VIDEO);
        this.isIVRPhoneMessageToggledOn = this.featureToggleService.isFeatureToggleOn(enumFeatureToggle.IVR_WAGER_MSG);
    }

    public ngOnDestroy() {
        if (this.completedBetsSub) {
            this.completedBetsSub.unsubscribe();
            this.completedBetsSub = null;
        }
        if (this.betShareDetailsSub) {
            this.betShareDetailsSub.unsubscribe();
            this.betShareDetailsSub = null;
        }
    }

    /**
     * Communicate upwards that a transaction's size has contracted.
     */
    public contractSize() {
        this.contract.emit();
    }

    public toggleExpandedView(bet: IWager): void {
        // Flatten multidimensional array into array of all completed bets
        const allCompletedBets = [].concat(...this.completedBetsList.map(betList => betList.bets));
        this.closeExpandedBet(allCompletedBets, bet);
    }

    /**
     * Find the expanded bet and collapse it as the other bet is expanded
     * @param {IWager[]} completedBets - Array of all completed bets
     * @param {IWager} nextBet - bet to expand
     */
    private closeExpandedBet(completedBets: IWager[], nextBet: IWager): void {
        // There is at most one bet expanded at a time
        completedBets.filter(bet => bet.isExpanded === true)
                     .forEach(bet => bet.isExpanded = false);

        nextBet.isExpanded = true;
    }
}
