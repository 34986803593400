<section class="live-ticket-container">
    <div class="live-ticket-content">
        <div class="live-ticket" [class.is-disabled]="!entriesAreSelected">
            <div class="live-ticket__row error-row" *ngIf="(!isSubmittedState && entriesAreSelected && errorText) || (isSubmittedState && errorText)">
                <div class="bet-error-message no-icon">
                    {{errorText}}
                    <a *ngIf="insufficientFundsError" (click)="openDepositFlow()">{{depositButtonText}}</a>
                </div>
            </div>
            <div class="live-ticket__row has-message" *ngIf="isSubmittedState && !errorText">
                <ng-container *ngIf="!submitting; else submittingRepeat">
                    <div class="message-wrap is-shown">
                        <div class="animation-svg-wrapper">
                            <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                                <path class="checkmark__check" fill="none"
                                      d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                            </svg>
                        </div>
                    </div>
                    <div class="ts-message"><p>Your bet has been placed</p></div>
                </ng-container>
                <ng-template #submittingRepeat>
                    <div class="message-wrap demo-message is-shown">
                        <div class="ts-message has-padding"><p>Placing Bet...</p></div>
                    </div>
                </ng-template>
            </div>
            <div class="live-ticket__row has-transaction-id" *ngIf="!!lastTransactionId">
                <span class="label">Transaction ID:</span>
                <span id="live-ticket-transaction-id" class="id">{{lastTransactionId}}</span>
            </div>


            <div class="live-ticket__title-bar">
                <h2 class="title">
                    <div id="live-ticket-track-name" class="track-name">{{wagerState?.basicTrack?.DisplayName}}</div>
                    <div id="live-ticket-race-number" class="race-number">RACE {{wagerState?.basicTrack?.RaceNum}}</div>
                </h2>
                <span id="live-ticket-bet-amount" class="bet-amount">{{wagerState?.betNav?.amount?.value | currency:'USD':'symbol-narrow'}}</span>
                <span class="live-ticket__bet-type" id="live-ticket-bet-type">
                    <span id="live-ticket-pool-code">{{wagerState?.betNav?.type?.code}}</span>
                    <span *ngIf="displayModifier">
                        <span id="live-ticket-modifier"> {{displayModifier}}</span>
                    </span>
                </span>
            </div>
            <ng-container *ngIf="!entriesAreSelected || wagerClosed; else showTicketRow">
                <div class="live-ticket__row has-message">
                    <span class="ts-message has-padding"><p id="live-ticket-default-message">{{message}}</p></span>
                </div>
            </ng-container>
            <ng-template #showTicketRow>
                <div class="live-ticket__row">
                    <cdux-runner-list id="live-ticket-entries"
                        class="bet-container place-leg-pick-container"
                            [runnerList]="runnerListConfig"
                            [collapseBoxedRunners]="false"
                            [trackType]="wagerState?.basicTrack?.TrackType"
                            [betType]="wagerState?.betNav?.type"
                        ></cdux-runner-list>
                    <div class="keep-picks-option">
                        <p>KEEP <br>
                            PICKS</p>
                        <div class="checkbox remember-me" [ngClass]="{'is-checked':keepPicks}">
                            <input id="rememberPs" type="checkbox" name="check"  [checked]="keepPicks"  (click)="handleKeepPicksToggle()">
                            <label for="rememberPs"></label>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="clear"></div>
            <div class="live-ticket__total-row">
                <span id="live-ticket-bet-total-label" class="total">Bet Total:</span>
                <!-- Display total as $0 whenever wager is invalid -->
                <span id="live-ticket-bet-total-amount" class="amount">{{ total | currency:'USD':'symbol-narrow' }}</span>
            </div>
            <div class="live-ticket__function-row-actions">
                <ng-template #loadingDots>
                    <div class="loader-block">
                        <div class="loader-dots">
                            <i class="dots-animated"></i>
                            <i class="dots-animated"></i>
                            <i class="dots-animated"></i>
                            <i class="dots-animated"></i>
                        </div>
                    </div>
                </ng-template>
                <ng-container *ngIf="!isSubmittedState; else submittedWagerFunctions">
                    <div class="live-ticket__function-row-primary" *ngIf="isBetShareEnabled && allowsBetShare && !advancedOptionsToggle && showShareBetButton">
                        <div
                            id="btn-live-ticket-share"
                            class="btn-live-ticket-primary"
                            [class.is-showing]="sharing"
                            [class.is-inactive]="!isValid && !sharing && !saving && !submitting"
                            (click)="isValid && !sharing && initiateBetShare()">
                            <span *ngIf="!sharing; else loadingDots">Share Bet</span>
                        </div>
                    </div>
                    <div *ngIf="!tournamentSelected" class="live-ticket__function-row-primary">
                        <div id="btn-live-ticket-save"
                            class="btn-live-ticket-primary"
                            [class.is-showing]="saving"
                            [class.is-inactive]="!isValid && !sharing && !saving && !submitting"
                            *ngIf="isSavedBetsEnabled" (click)="isValid && !saving && saveBet()">
                            <span *ngIf="!saving; else loadingDots">Save Bet</span>
                        </div>
                    </div>
                    <div class="live-ticket__function-row-action">
                        <div id="btn-live-ticket-submit"
                            class="btn-live-ticket-action"
                            [class.is-showing]="submitting"
                            [class.is-inactive]="!isValid && !sharing && !saving && !submitting"
                            (click)="isValid && !submitting && submitQuickBet()">
                            <span *ngIf="!submitting; else loadingDots">Submit Bet</span>
                            <div class="button-animation-container">
                                <div class="animation-svg-wrapper">
                                    <div class="overlay"></div>
                                    <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                        <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"></circle>
                                        <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-template #submittedWagerFunctions>
                    <!-- TODO: Add click event for Repeat Bet -->
                    <div class="live-ticket__function-row-primary">
                        <div
                            id="btn-live-ticket-repeat"
                            class="btn-live-ticket-primary"
                            [class.is-showing]="submitting"
                            (click)="!submitting && submitQuickBet(betActionTypes.REPEAT_BET)"
                            (clickOutside)="finalizeSubmittingBet()">
                            <ng-container *ngIf="!submitting; else loadingDots">Repeat Bet</ng-container>
                        </div>
                    </div>
                    <div class="live-ticket__function-row-primary">
                        <div id="btn-live-ticket-done" class="btn-live-ticket-primary" (click)="finalizeSubmittingBet()">
                            <span>Done</span>
                        </div>
                    </div>
                </ng-template>
            </div>
            <div *ngIf="!runnerListConfig?.alternateBettingInterest && !tournamentSelected && advancedOptionsToggle && canShowAdvancedOptions && isValid && !isSubmittedState" class="live-ticket__function-row"><!-- BEGIN Advanced Options row -->
                <div class="live-ticket__function-row-options label" (click)="handleAdvOptsExpansion()" id="live-ticket-adv-opts-link">
                    ADVANCED OPTIONS<div class="icon-expand-arrow" [ngClass]="{'is-active':isAdvancedOptionsExpanded}"></div>
                </div>
                <div *ngIf="isAdvancedOptionsExpanded" class="live-ticket__function-row-icons">
                    <div *ngIf="isBetShareEnabled && allowsBetShare && showShareBetButton" class="icon-wrap" id="live-ticket-adv-opts-share-btn"
                        [class.is-showing]="sharing"
                        [class.is-inactive]="!isValid && !sharing && !saving && !submitting"
                        (click)="isValid && !sharing && initiateBetShare()">
                        <i class="icon-live-ticket betshare"></i>
                        <span *ngIf="!sharing; else loadingDots" class="label">SHARE BET</span>
                    </div>
                    <div *ngIf="isConditionalWagerToggleOn && allowsConditional && isValidCondWagerType" class="icon-wrap" id="live-ticket-adv-opts-conditions-btn"
                        [class.is-showing]="conditioning"
                        [class.is-inactive]="!isValid && !conditioning && !saving && !submitting"
                        (click)="isValid && !conditioning && initiateConditions()">
                        <i class="icon-live-ticket conditional"></i>
                        <span class="label">CONDITIONS</span>
                    </div>
                </div>
            </div><!-- END Advanced Options row -->
        </div>
    </div>
</section>
